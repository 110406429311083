<template>
  <div>
    <div
      v-if="detail.otherExplain !== undefined"
      class="detail-other-container"
    >
      {{ detail.otherExplain }}
    </div>
    <div
      v-else
      class="detail-node-container"
    >
      <div class="start-arrival-detail item-contain">
        <div class="airport">
          {{ detail.leftTop }}
        </div>
        <div class="time">
          {{ detail.leftBottom }}
        </div>
      </div>
      <div class="center-detail item-contain">
        <div class="desc">
          {{ detail.center }}
        </div>
        <div class="icon">
          <div class="icon-right"></div>
        </div>
      </div>
      <div class="start-arrival-detail item-contain">
        <div class="airport">
          {{ detail.rightTop }}
        </div>
        <div class="time">
          {{ detail.rightBottom }}
          <span
            v-if="detail.flight"
            class="time2"
          >
            {{ Number(detail.day) > 0 ? "+" + detail.day : "" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailNode",
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-other-container {
  height: 140px;
  width: 680px;
  padding: 15px 20px 20px 20px;
}
.detail-node-container {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;

  .item-contain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .center-detail {
    width: 200px;
    text-align: center;
    .desc {
      font-size: 12px;
      color: #91a1b7;
    }
    .icon {
      margin-top: 2px;
      width: 60px;
      height: 6px;
      border-bottom: 1px solid #dfdfdf;

      .icon-right {
        float: right;
        margin: 3px -1px 3px 0;
        height: 1px;
        width: 6px;
        background-color: #dfdfdf;
        transform: rotate(45deg);
      }
    }
  }
  .start-arrival-detail {
    flex-shrink: 0;
    width: 240px;
    text-align: center;
    .airport {
      min-height: 14px;
      font-size: 14px;
      color: #636c78;
    }
    .time {
      position: relative;
      margin-top: 14px;
      min-height: 18px;
      font-size: 22px;
      color: #333;
      .time2 {
        position: absolute;
        font-size: 14px;
        color: #49bef2;
        top: -3px;
        right: -20px;
      }
    }
  }
}
</style>
