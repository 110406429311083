var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-top-contain" },
    [
      _vm.icon.length > 0
        ? _c("en-icon", {
            staticClass: "flight-icon",
            style: { fill: "#F7cF00" },
            attrs: { name: _vm.icon, size: "20px" },
          })
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "name",
          style: _vm.icon.length > 0 ? "" : "margin-left:17px;",
        },
        [_vm._v(" " + _vm._s(_vm.name) + " ")]
      ),
      _c("en-trip-node-status", {
        staticClass: "status",
        attrs: { status: Number(_vm.status), "is-dark": true },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }