<template>
  <div class="content-contain">
    <en-icon
      :name="iconName"
      :style="'fill:'+fillColor+';'"
      class="icon"
    ></en-icon>
    <div class="name">
      {{ name + "：" }}
    </div>
    <div class="content">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailLabel",
  props: {
    iconName: {
      type: String,
      default: "renyuan-wodehangcheng"
    },
    fillColor: {
      type: String,
      default: ""
    },
    name: {
      type: String
    },
    content: {
      type: String
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.content-contain {
  display: flex;
  .icon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
  .name {
    flex-shrink: 0;
    margin-left: 8px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    color: #333;
  }
  .content {
    min-height: 16px;
    line-height: 16px;
    font-size: 12px;
    color: #636c78;
  }
}
</style>
