var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "supplement-content-container",
      style: _vm.contentHeightStyle,
    },
    [
      _c("div", { staticClass: "supplement-top-contain" }, [
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titleText))]),
        _c(
          "div",
          { staticClass: "top-content" },
          [
            _vm.detailData.shareUserList !== undefined &&
            _vm.detailData.shareUserList.length > 0
              ? _c(
                  "en-button",
                  {
                    attrs: {
                      type: "text",
                      "icon-color": "#FFAD2C",
                      icon: "gongxiang-wodehangcheng",
                    },
                  },
                  [_vm._v(" 共享 ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { on: { click: _vm.closeClick } },
          [
            _c("en-icon", {
              staticClass: "close-icon",
              style: { fill: "#A9B5C6" },
              attrs: { name: "guanbi-danchuang", size: "14px" },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "body-container" }, [
        _c(
          "div",
          { staticClass: "top-container" },
          [
            _c("detailTop", {
              attrs: { name: _vm.topName, status: _vm.detailData.status },
            }),
            _c("borderLine"),
            _c("detailNode", { attrs: { detail: _vm.detailNode } }),
            _c("borderLine"),
            _c(
              "div",
              { staticClass: "top-bottom-contain" },
              [
                _c("en-icon", {
                  staticClass: "bu-icon",
                  attrs: { name: "budan-wodehangcheng", size: "18px" },
                }),
                _c("tripTotalAmount", {
                  staticClass: "total-amount",
                  attrs: { "total-amount": _vm.detailData.totalAmount },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom-container" },
          [
            _vm._m(0),
            _c("borderLine"),
            _c("div", { staticClass: "bottom2-contain" }, [
              _vm.detailData.type === "000"
                ? _c(
                    "div",
                    [
                      _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "renyuan-wodehangcheng",
                          "fill-color": "#A9B5C6",
                          name: "乘机人员",
                          content: _vm.userName(),
                        },
                      }),
                      _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "cangweidengji-wodehangcheng",
                          "fill-color": "#A9B5C6",
                          name: "舱位等级",
                          content: "经济舱",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.detailData.type === "001"
                ? _c(
                    "div",
                    [
                      _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "renyuan-wodehangcheng",
                          "fill-color": "#A9B5C6",
                          name: "入住人员",
                          content: _vm.userName(),
                        },
                      }),
                      _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "daodachengshi-budan",
                          "fill-color": "#A9B5C6",
                          name: "入住城市",
                          content: "长沙",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.detailData.type === "002"
                ? _c(
                    "div",
                    [
                      _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "renyuan-wodehangcheng",
                          "fill-color": "#A9B5C6",
                          name: "乘车人员",
                          content: _vm.userName(),
                        },
                      }),
                      _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "cangweidengji-wodehangcheng",
                          "fill-color": "#A9B5C6",
                          name: "乘坐席别",
                          content: _vm.seatName(),
                        },
                      }),
                    ],
                    1
                  )
                : _vm.detailData.type === "003"
                ? _c(
                    "div",
                    [
                      _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "renyuan-wodehangcheng",
                          "fill-color": "#A9B5C6",
                          name: "乘车人员",
                          content: _vm.userName(),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _vm.detailData.type !== "004"
                    ? _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "shixiangshuoming-budan",
                          "fill-color": "#A9B5C6",
                          name: "事项说明",
                          content: _vm.detailData.explain,
                        },
                      })
                    : _vm._e(),
                  _vm.detailData.standardMemo
                    ? _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "chaobiaoshiyou-wodehangcheng",
                          "fill-color": "#F76B6B",
                          name: "超标事由",
                          content: _vm.detailData.standardMemo,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bottom1-contain" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 订单信息 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }