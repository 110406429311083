var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "border-line-contain" }, [
    _vm.topGray ? _c("div", { staticClass: "top-bg" }) : _vm._e(),
    _vm.bottomGray ? _c("div", { staticClass: "bottom-bg" }) : _vm._e(),
    _c("div", { staticClass: "left-border" }),
    _c("div", { staticClass: "center-line" }),
    _c("div", { staticClass: "right-border" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }