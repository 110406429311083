var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.detail.otherExplain !== undefined
      ? _c("div", { staticClass: "detail-other-container" }, [
          _vm._v(" " + _vm._s(_vm.detail.otherExplain) + " "),
        ])
      : _c("div", { staticClass: "detail-node-container" }, [
          _c("div", { staticClass: "start-arrival-detail item-contain" }, [
            _c("div", { staticClass: "airport" }, [
              _vm._v(" " + _vm._s(_vm.detail.leftTop) + " "),
            ]),
            _c("div", { staticClass: "time" }, [
              _vm._v(" " + _vm._s(_vm.detail.leftBottom) + " "),
            ]),
          ]),
          _c("div", { staticClass: "center-detail item-contain" }, [
            _c("div", { staticClass: "desc" }, [
              _vm._v(" " + _vm._s(_vm.detail.center) + " "),
            ]),
            _vm._m(0),
          ]),
          _c("div", { staticClass: "start-arrival-detail item-contain" }, [
            _c("div", { staticClass: "airport" }, [
              _vm._v(" " + _vm._s(_vm.detail.rightTop) + " "),
            ]),
            _c("div", { staticClass: "time" }, [
              _vm._v(" " + _vm._s(_vm.detail.rightBottom) + " "),
              _vm.detail.flight
                ? _c("span", { staticClass: "time2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          Number(_vm.detail.day) > 0 ? "+" + _vm.detail.day : ""
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("div", { staticClass: "icon-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }