<template>
  <div class="flight-content-container" :style="contentHeightStyle">
    <div class="flight-top-contain">
      <span class="title">机票详情</span>
      <div class="top-content">
        <!--
        <en-button
          type="text"
          icon-color="#FFAD2C"
          icon="gongxiang-wodehangcheng"

        >
          共享
        </en-button>
        -->
      </div>
      <div @click="closeClick">
        <en-icon
          name="guanbi-danchuang"
          class="close-icon"
          size="14px"
          :style="{ fill: '#A9B5C6' }"
        ></en-icon>
      </div>
    </div>
    <div
      class="body-container"
      :style="
        operationShow
          ? 'max-height: calc(100% - 64px - 52px);'
          : 'max-height:calc(100% - 52px);'
      "
    >
      <div class="top-container">
        <detailTop
          icon="bitian"
          :name="flightNoDetail()"
          :status="detailData.status"
        ></detailTop>

        <borderLine></borderLine>
        <detailNode :detail="detailNode"></detailNode>
        <borderLine :bottom-gray="tuigaiOpen"></borderLine>

        <div
          class="top-bottom-contain"
          :style="tuigaiOpen ? 'background-color:#FBFBFC;height:auto;' : ''"
        >
          <div class="tuigai" @click="tuigaiOpen = !tuigaiOpen">
            <span class="text" :style="tuigaiOpen ? 'color:#3e90fe;' : ''"
              >退改规则</span
            >
            <en-icon
              class="jiantou"
              size="9px"
              name="zhankai"
              :style="tuigaiOpen ? 'transform: rotate(180deg);' : ''"
            ></en-icon>
          </div>
          <!-- <tripTotalAmount
            class="total-amount"
            :total-amount="detailData.totalAmount"
          ></tripTotalAmount> -->
          <el-popover
              popper-class="custom-flight-popover"
              placement="bottom-end"
              trigger="hover"
            >
            <div class="amount-flight-popover">
              <div class="line-container">
                <span class="content">票价格</span>
                <span class="content">¥{{detailData.salePrice}}</span>
              </div>
              <div class="line-container">
                <span class="content">机建燃油</span>
                <span class="content">¥{{Number(detailData.buildFee) + Number(detailData.fuelFee)}}</span>
              </div>
            </div>
            <tripTotalAmount
              slot="reference"
              class="total-amount"
              :total-amount="detailData.totalAmount"
            ></tripTotalAmount>
          </el-popover>
          <div v-if="tuigaiOpen" class="tuigai-contain">
            <en-tabs
              v-if="tuigaiDataList.length > 1"
              v-model="activeTab"
              :list="tabList"
              type="white"
              @change="changeTabs"
            >
            </en-tabs>
            <div v-else style="height:12px;"></div>

            <el-table
              border
              :data="tuigaiTableData"
              style="width: 100%"
              :show-header="false"
              :cell-style="cellStyle"
              class="tuigaiTable"
            >
              <el-table-column prop="type"> </el-table-column>
              <el-table-column prop="column1"> </el-table-column>
              <el-table-column prop="column2"> </el-table-column>
              <el-table-column prop="column3"> </el-table-column>
            </el-table>
            <div style="height:20px;"></div>
          </div>
        </div>
      </div>
      <div class="bottom-container" @click="editClick">
        <div class="bottom1-contain">
          <div class="title">
            {{ orderNumberDetail }}
          </div>
          <div class="time">
            {{ orderDate() }}
          </div>
        </div>
        <borderLine></borderLine>
        <div class="bottom2-contain">
          <detailLabel
            icon-name="renyuan-wodehangcheng"
            fill-color="#A9B5C6"
            name="乘机人员"
            :content="passengerName()"
            class="detail-label"
          />
          <detailLabel
            icon-name="cangweidengji-wodehangcheng"
            fill-color="#A9B5C6"
            name="舱位等级"
            :content="cabinName()"
            class="detail-label"
          />
          <detailLabel
            v-if="detailData.explain"
            icon-name="shixiangshuoming-budan"
            fill-color="#A9B5C6"
            name="事项说明"
            :content="detailData.explain"
            class="detail-label"
          />
          <detailLabel
            v-if="detailData.standardMemo"
            icon-name="chaobiaoshiyou-wodehangcheng"
            fill-color="#F76B6B"
            name="超标事由"
            :content="detailData.standardMemo"
            class="detail-label"
          />
        </div>

        <div v-if="!!changeList && changeList.length > 0">
          <borderLine></borderLine>
          <div class="bottom3-contain">
            <div
              class="biangeng"
              onClick="event.cancelBubble = true"
              @click="biangengOpen = !biangengOpen"
            >
              <span class="text" :style="biangengOpen ? 'color:#3e90fe;' : ''"
                >变更详情</span
              >
              <en-icon
                class="jiantou"
                size="9px"
                name="zhankai"
                :style="biangengOpen ? 'transform: rotate(180deg);' : ''"
              ></en-icon>
            </div>
            <div v-if="biangengOpen" class="biangeng-item-contain">
              <div
                v-for="item in changeList"
                :key="item.id"
                class="biangeng-item"
              >
                <span class="type">{{ changeStatusDetail(item.changeStatus) }}</span>
                <span class="time">{{ item.createTime }}</span>
                <div class="reason-block">
                  <span class="reason" v-if="item.changeReason">{{ "退票事由：" + item.changeReason }}</span>
                  <span class="result">{{ "处理结果：" + item.changeResult }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="operationShow" class="operation-contain">
      <div v-if="detailData.shareUserList" class="tips-contain">
        <en-icon
          name="bitian"
          size="16px"
          :style="{ fill: '#F7BF27' }"
        ></en-icon>
        <div class="tips">
          {{ shareUserName() }}
        </div>
      </div>
      <en-button @click="tuiButtonClick" v-if="tuiButton" class="button" type="withe">
        退票
      </en-button>
      <en-button @click="gaiButtonClick" v-if="gaiButton" class="button">
        改签
      </en-button>
      <en-button @click="reBookButtonClick" v-if="reBookButton" class="button">
        重新预定
      </en-button>
    </div>

    <!-- 退票弹框 -->
    <rTicketDialog
      v-if="rTicketDialogShow"
      :dialog-visible.sync="rTicketDialogShow"
      :is-refund="isRefund"
      @getRefundTicketValue="getRefundTicketValue"
      @getChangeTicketValue="getChangeTicketValue"
    ></rTicketDialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { tripService } from "@/api";
import tripTotalAmount from "../../components/trip-total-amount";
import borderLine from "../components/border-line";
import detailTop from "../components/detail-top";
import detailLabel from "../components/detail-label";
import detailNode from "../components/detail-node";
import rTicketDialog from "../../components/refundTicketDialog";
import nodeDetailData from "../nodeDetailData";

export default {
  name: "FlightNodeDetail",
  components: {
    borderLine,
    tripTotalAmount,
    detailLabel,
    detailNode,
    detailTop,
    rTicketDialog
  },
  props: {
    contentHeight: {
      type: Number,
      default: 800
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      contentHeightStyle: "",
      bottomContainerStyle: "min-height: 237px;",
      tuigaiOpen: false,
      rTicketDialogShow: false,
      isRefund: true,
      biangengOpen: true,
      detailNode: {},
      detailData: {},
      tripNodeId: "",
      tabList: [
        {
          id: "qucheng",
          name: "去程"
        },
        {
          id: "fancheng",
          name: "返程"
        }
      ],
      activeTab: "qucheng",
      tuigaiData: {},
      tuigaiDataList: [],
      tuigaiTableData: [],
      changeList: [],
      content: ""
    };
  },
  mixins: [nodeDetailData],
  watch: {
    contentHeight(val) {
      this.contentHeightStyle = `max-height:${val}px;`;
    },
    detail() {
      this.updateDetailData();
    },
    tuigaiData() {
      this.updateTuigaiTableData();
    }
  },
  computed: {
    tuiButton() {
      if ((this.detailData.tripStatus === "003"
        || this.detailData.tripStatus === "004"
        || this.detailData.tripStatus === "005")
        && this.detailData.status === "011") {
        return true;
      }
      // return true;
      return false;
    },
    gaiButton() {
      if ((this.detailData.tripStatus === "003"
        || this.detailData.tripStatus === "004"
        || this.detailData.tripStatus === "005")
        && this.detailData.status === "011") {
        return true;
      }
      // return true;
      return false;
    },
    reBookButton() {
      if ((this.detailData.tripStatus === "003"
        || this.detailData.tripStatus === "004"
        || this.detailData.tripStatus === "005")
        && this.detailData.status === "001") {
        return true;
      }
      return false;
    },
    operationShow() {
      if (this.tuiButton || this.gaiButton || this.reBookButton || this.detailData.shareUserList) {
        return true;
      }
      return false;
    },
    orderNumberDetail() {
      if (this.detailData.orderId) {
        return `订单编号：${this.detailData.orderId}`;
      }
      return "订单编号：";
    }
  },
  mounted() {
    this.contentHeightStyle = `max-height:${this.contentHeight}px;`;
    // this.tuigaiDataList = [
    //   {
    //     messageAgo: "起飞前168小时（不含）前",
    //     messageAfter:
    //       "起飞前72小时（不含）前|¥250/人^起飞前4小时（不含）前|¥350/人^起飞前4小时之后",
    //     isAllowedToSign: "不得签转",
    //     changeFeeAgo: "¥100/人",
    //     changeFeeAfter: "¥300/人",
    //     changeTicketRuleInfo:
    //       "起飞前168小时（含）前|¥100/人^起飞前72小时（含）前|¥150/人^起飞前4小时（含）前|¥200/人^起飞前4小时之后|¥300/人",
    //     returnFeeAgo: "¥150/人",
    //     returnFeeAfter: "¥450/人",
    //     refundTicketRuleInfo:
    //       "起飞前168小时（不含）前|¥150/人^起飞前72小时（不含）前|¥250/人^起飞前4小时（不含）前|¥350/人^起飞前4小时之后|¥450/人"
    //   }
    // ];
    // this.tuigaiData = { ...this.tuigaiDataList[0] };
  },
  methods: {
    // 航司航班号
    flightNoDetail() {
      if (this.detailData.crrierCh && this.detailData.flightNo) {
        return this.detailData.crrierCh + this.detailData.flightNo;
      }
      return "";
    },
    // 乘机人员
    passengerName() {
      if (this.detailData.userList) {
        let names = "";
        for (let i = 0; i < this.detailData.userList.length; i++) {
          const user = this.detailData.userList[i];
          if (names.length === 0) {
            names = user.name;
          } else {
            names = `${names},${user.name}`;
          }
        }
        return names;
      }
      return "";
    },
    // 舱位名称
    cabinName() {
      const type = this.detailData.classType;
      if (type === "000") {
        return "经济舱";
      }
      if (type === "001") {
        return "公务舱";
      }
      if (type === "002") {
        return "头等舱";
      }
      return "";
    },
    // 订单日期
    orderDate() {
      if (this.detailData.createTime) {
        return dayjs(this.detailData.createTime).format("YYYY-MM-DD");
      }
      return "";
    },
    // 共享人
    shareUserName() {
      if (this.detailData.shareUserList) {
        const user = this.detailData.shareUserList[0];
        return `由${user.name}共享`;
      }
      return "";
    },
    // 变更状态
    changeStatusDetail(status) {
      if (status === "000") {
        return "改签成功";
      }
      if (status === "001") {
        return "改签成功";
      }
      if (status === "002") {
        return "改签失败";
      }
      if (status === "003") {
        return "退票成功";
      }
      if (status === "004") {
        return "退票失败";
      }
      if (status === "005") {
        return "预订失败";
      }
      if (status === "006") {
        return "退房成功";
      }
      if (status === "007") {
        return "延期事由";
      }
      if (status === "008") {
        return "退房失败";
      }
      if (status === "009") {
        return "即时叫车";
      }
      if (status === "010") {
        return "预约失败";
      }
      if (status === "011") {
        return "取消用车";
      }
      if (status === "012") {
        return "重新预订";
      }
      if (status === "013") {
        return "移动成功";
      }
      return "";
    },
    // 处理退改数据
    updateTuigaiTableData() {
      this.tuigaiTableData = [
        {
          type: "类型",
          column1: this.tuigaiData.messageAgo,
          column2: this.tuigaiData.messageAfter,
          column3: "备注"
        },
        {
          type: "退票费",
          column1: this.tuigaiData.returnFeeAgo,
          column2: this.tuigaiData.returnFeeAfter,
          column3: this.tuigaiData.refundTicketRuleInfo
        },
        {
          type: "同舱改期费",
          column1: this.tuigaiData.changeFeeAgo,
          column2: this.tuigaiData.changeFeeAfter,
          column3: this.tuigaiData.changeTicketRuleInfo
        },
        {
          type: "签转条件",
          column1: this.tuigaiData.isAllowedToSign
        }
      ];
    },
    // 更新显示数据
    updateDetailData() {
      this.detailData = { ...this.detail };
      this.tripNodeId = this.detailData.id;
      console.log(this.detailData);

      const obj = {};
      obj.leftTop = `${this.detailData.depCityCh}${this.detailData.depAirportCh}${this.detailData.fromTerminal || ""}`;
      obj.rightTop = `${this.detailData.arrCityCh}${this.detailData.arrAirportCh}${this.detailData.arrTerminal || ""}`;
      obj.leftBottom = dayjs(this.detailData.takeoffTime).format("HH:mm");
      obj.rightBottom = dayjs(this.detailData.arriveTime).format("HH:mm");
      obj.day = dayjs(this.detailData.arriveTime).diff(dayjs(this.detailData.takeoffTime), "day");
      const minute = dayjs(this.detailData.arriveTime).diff(dayjs(this.detailData.takeoffTime), "minute");
      obj.center = `${parseInt(minute / 60, 10)}h${minute % 60}m`;
      obj.flight = true;
      this.detailNode = obj;

      this.tuigaiData = { ...this.detailData.ticketRule };

      if (this.detailData.changeInfos) {
        this.changeList = [...this.detailData.changeInfos];
      }
    },
    // 切换标签
    changeTabs(res) {
      if (res.id === "qucheng") {
        this.tuigaiData = { ...this.tuigaiDataList[0] };
      } else {
        this.tuigaiData = { ...this.tuigaiDataList[1] };
      }
    },
    // 编辑
    editClick() {
      if (this.operationShow) {
        this.bottomContainerStyle = "min-height: 237px;";
      } else {
        this.bottomContainerStyle = "min-height: 300px;";
      }
    },
    // 样式
    cellStyle({ rowIndex }) {
      if (rowIndex === 0) {
        return "color:#333;font-weight:500;background-color:#F6FAFD;";
      }
      if (rowIndex % 2 === 0) {
        return "background-color:#F7F9F9";
      }
      return "";
    },
    // 关闭
    closeClick() {
      this.$emit("closeClick");
    },
    // 重新预定
    async reBookButtonClick() {
      const rsp = await this.requestTryNewTripNode({
        tripNodeId: this.tripNodeId,
        tripNodeType: "000"
      });
      console.log(rsp);
      if (rsp === undefined) {
        this.closeClick();
        this.$router.push({
          path: "/business-travel/flight",
          query: this.detailData
        });
      } else if (rsp) {
        this.$router.push({ path: "/business-travel/flight/order" });
      }
    },
    // 退票
    tuiButtonClick() {
      this.isRefund = true;
      this.rTicketDialogShow = true;
    },
    // 改签
    gaiButtonClick() {
      if (this.detailData.endorseFlag === "001") {
        this.$message("改签机票不允许退票, 请联系客服人员");
        return;
      }
      this.isRefund = false;
      this.rTicketDialogShow = true;
    },
    // 退票回调
    async getRefundTicketValue(reason, isVoluntarily) {
      const params = {
        ticketNo: this.detailData.ticketNo,
        passengerName: this.detailData.passengerName,
        idType: this.detailData.idType,
        idNumber: this.detailData.idNumber,
        planeNodeId: this.detailData.id,
        tripId: this.detailData.tripId,
        changeReason: reason,
        changeType: isVoluntarily ? "000" : "001"
      };
      console.log("退票", params);
      const rsp = await tripService.queryApplyReturnTicketApp(params);
      console.log(rsp);
      this.$message.success("退票申请已提交");
    },
    // 改签回调
    async getChangeTicketValue(reason, isVoluntarily) {
      const params = {
        planeNodeId: this.detailData.id,
        flightNo: this.detailData.flightNo,
        carrier: this.detailData.crrierCh,
        cabin: this.detailData.cabin,
        depAirport: this.detailData.depAirport,
        arrAirport: this.detailData.arrAirport,
        takeoffTime: this.detailData.takeoffTime,
        ticketPrice: this.detailData.ticketPrice,
        tripId: this.detailData.tripId,
        changeReason: reason,
        changeType: isVoluntarily ? "000" : "001"
      };
      console.log("改签", params);
      const rsp = await tripService.queryValidateIsChangeApp(params);
      const flag = rsp.flag;
      if (flag) {
        const reasonId = rsp.reasonId;
        console.log(reasonId);
        this.$router.push({
          path: "/business-travel/flight",
          query: { reasonId }
        });
      } else {
        this.$message("不可改签");
      }
      console.log(rsp);
    }
  }
};
</script>

<style lang="scss" scoped>
.flight-content-container {
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  .flight-top-contain {
    position: relative;
    height: 52px;
    .title {
      position: absolute;
      left: 20px;
      top: 19px;
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      text-align: left;
      color: #333;
    }
    .close-icon {
      position: absolute;
      right: 20px;
      top: 19px;
      width: 14px;
      height: 14px;
    }
    .top-content {
      position: absolute;
      right: 54px;
      top: 0;
      height: 52px;
      display: flex;
      align-items: right;
    }
  }
  .body-container {
    background: #f5f7fa;
    width: 100%;
    max-height: calc(100% - 52px);
    border-radius: 0 0 6px 6px;
    overflow-y: auto;
    .top-container {
      position: relative;
      margin: 0 20px;
      min-height: 230px;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 6px;
      border: 1px solid #e8ecf2;

      .top-bottom-contain {
        position: relative;
        height: 44px;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .tuigai {
          position: absolute;
          top: 15px;
          left: 18px;
          height: 13px;
          display: flex;
          align-items: center;
          .text {
            color: #333;
          }
          .jiantou {
            margin-left: 10px;
            fill: #a9b5c6;
          }
        }
        .total-amount {
          position: absolute;
          top: 10px;
          right: 18px;
        }
        .tuigai-contain {
          padding-top: 35px;
          margin-left: 20px;
          margin-right: 20px;
          height: auto;
          .tuigaiTable {
            border-radius: 4px;
            border-top-left-radius: 0;
          }
          & /deep/ .en-tabs {
            line-height: 34px;
            padding: 0;
          }
          & /deep/ .en-tabs-item {
            height: 34px;
            padding: 0 16px 0 16px;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            width: 120px;
            &.active {
              color: #3e90fe;
            }
          }
          & /deep/ td {
            font-size: 12px;
            color: #636c78;
          }
          & /deep/ th {
            font-size: 12px;
            color: #333;
          }
        }
      }
    }
    .bottom-container {
      margin: 8px 20px 20px;
      width: calc(100% - 40px);
      min-height: 200px;
      border-radius: 6px;
      border: 1px solid #e8ecf2;
      background: #fff;
      .bottom1-contain {
        position: relative;
        height: 44px;
        width: 100%;
        .title {
          margin: 20px 0 0 20px;
          height: 12px;
          display: inline-block;
          color: #333;
        }
        .time {
          float: right;
          margin: 20px 20px 0 20px;
          height: 12px;
          display: block;
          color: #91a1b7;
        }
      }
      .bottom2-contain {
        width: 100%;
        border-radius: 0 0 6px 6px;
        padding-bottom: 10px;
        .detail-label {
          margin: 15px 20px 0;
        }
      }
      .bottom3-contain {
        position: relative;
        height: auto;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-bottom: 10px;
        .biangeng {
          margin-left: 18px;
          padding-top: 8px;
          height: 38px;
          display: flex;
          align-items: center;
          .text {
            color: #333;
          }
          .jiantou {
            margin-left: 10px;
            fill: #a9b5c6;
          }
        }
        .biangeng-item-contain {
          margin: 6px 20px 18px;
          .biangeng-item {
            position: relative;
            min-height: 80px;
            margin-bottom: 8px;
            padding: 11px 12px;
            border-radius: 4px;
            border: 1px solid #e8ecf2;
            background-color: #f6fafd;
            .type {
              position: absolute;
              left: 12px;
              height: 13px;
              color: #333;
            }
            .time {
              position: absolute;
              right: 12px;
              height: 13px;
              color: #91a1b7;
            }
            .reason-block{
              margin-top: 20px;
              left: 12px;
              span{
                position:inherit;
                display: block;
                min-height: 20px;
                color: #636c78;
              }
            }
          }
        }
      }
    }
  }
  .operation-contain {
    position: relative;
    height: 64px;
    padding: 0 20px;
    border-top: 1px solid #e8ecf2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button {
      min-width: 72px;
      height: 32px;
    }
    .tips-contain {
      position: absolute;
      min-width: 100px;
      height: 60px;
      top: 0;
      left: 20px;
      display: flex;
      align-items: center;
      .tips {
        padding-left: 6px;
        color: #636c78;
      }
    }
  }
}
.custom-flight-popover {
  .popper__arrow {
    &::after {
      // border-top-color: orange !important;
      // border-bottom-color: orange !important;
      border-top-color: #666975 !important;
      border-bottom-color: #666975 !important;
    }
  }
  .amount-flight-popover {
    margin: -13px;
    padding: 12px;
    // background-color: orange;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    height: auto;
    min-width: 267px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    .line-container {
      display: flex;
      justify-content: space-between;
    }
    .tips-container {
      display: flex;
    }
    .content {
      display: block;
      padding-bottom: 2px;
      color: #fff;
    }
    .last-content {
      color: #fff;
      margin-left: 3px;
    }
  }
}
</style>
