var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-contain" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            "show-close": false,
            visible: _vm.dialogOccupyVisible,
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogOccupyVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "body-contain", style: _vm.contentWHStyle },
            [
              _vm.allNodeList.length > 1
                ? _c(
                    "div",
                    {
                      staticClass: "previous",
                      on: { click: _vm.previousClick },
                    },
                    [
                      _c("en-icon", {
                        style: { fill: "#A9B5C680" },
                        attrs: {
                          name: "shangxiaqiehuan-wodehangcheng",
                          size: "48px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "en-loading",
                      rawName: "v-en-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "content-contain",
                },
                [
                  _vm.currentItem.tripNodeType === "000"
                    ? _c("flight", {
                        attrs: {
                          "content-height": _vm.dialogHeight,
                          detail: _vm.currentDetail,
                        },
                        on: { closeClick: _vm.closeClick },
                      })
                    : _vm._e(),
                  _vm.currentItem.tripNodeType === "001"
                    ? _c("hotel", {
                        attrs: {
                          "content-height": _vm.dialogHeight,
                          detail: _vm.currentDetail,
                        },
                        on: { closeClick: _vm.closeClick },
                      })
                    : _vm._e(),
                  _vm.currentItem.tripNodeType === "003"
                    ? _c("car", {
                        attrs: {
                          "content-height": _vm.dialogHeight,
                          detail: _vm.currentDetail,
                        },
                        on: { closeClick: _vm.closeClick },
                      })
                    : _vm._e(),
                  _vm.currentItem.tripNodeType === "002"
                    ? _c("supplement", {
                        attrs: {
                          "content-height": _vm.dialogHeight,
                          detail: _vm.currentDetail,
                        },
                        on: { closeClick: _vm.closeClick },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.allNodeList.length > 1
                ? _c(
                    "div",
                    { staticClass: "next", on: { click: _vm.nextClick } },
                    [
                      _c("en-icon", {
                        staticStyle: { transform: "rotate(180deg)" },
                        style: { fill: "#A9B5C680" },
                        attrs: {
                          name: "shangxiaqiehuan-wodehangcheng",
                          size: "48px",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }