<template>
  <div class="trip-total-contain">
    <span style="color:#636C78;font-size:12px;">总价：</span>
    <span style="color:#F78528;font-size:14px;">¥ </span>
    <span style="color:#F78528;font-size:16px;font-weight:500;">{{
      totalAmount
    }}</span>
  </div>
</template>

<script>
export default {
  name: "TripTotalAmount",
  props: {
    totalAmount: {
      type: String,
      default: "0.00"
    }
  },
  data() {
    return {};
  }
};
</script>
