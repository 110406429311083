import { tripService } from "@/api";
// import { request } from "en-js";
// import dayjs from "dayjs";
// import { enEqual } from "@/tools/compare";
// import Store from "@/store";
// import { cloneDeep } from "lodash";

// 数据处理方法s
const dataAndUIMethods = {
  loadCurrentData() {
    this.dataList = [...this.allNodeList];
    for (let i = 0; i < this.dataList.length; i++) {
      const tmpNode = this.dataList[i];
      if (this.currentNode.id === tmpNode.id) {
        this.currentIndex = i;
        break;
      }
    }
    this.currentItem = { ...this.dataList[this.currentIndex] };
  }
};

// 事件方法
const actionMethods = {
  previousClick() {
    // if (this.currentIndex === 0) {
    //   this.$message("已经是第一条数据了!");
    //   return;
    // }
    const currentIndex = this.dataList.indexOf(this.currentItem);
    let index = currentIndex - 1;
    if (index < 0) {
      index = this.dataList.length - 1;
    }
    this.currentItem = this.dataList[index];
  },
  nextClick() {
    // if (this.currentIndex >= this.dataList.length - 1) {
    //   this.$message("已经是最后一条数据了!");
    //   return;
    // }
    let index = this.currentIndex + 1;
    if (index >= this.dataList.length) {
      index = 0;
    }
    this.currentIndex = index;
    this.currentItem = this.dataList[index];
  }
};

// 接口请求方法
const requestMethods = {
  // 请求查看节点详情
  async requestNodeDetail(params) {
    this.loading = true;
    const nodeDetail = await tripService.queryTripNodeDetail(params);
    if (this.currentItem.tripNodeType === "000") {
      const param = {
        outOrderId: nodeDetail?.orderId,
        carrier: nodeDetail?.airCode,
        flightNo: nodeDetail?.flightNo,
        cabin: nodeDetail?.cabin,
        depAirport: nodeDetail?.depAirport,
        arrAirport: nodeDetail?.arrAirport,
        outTicketTime: nodeDetail?.outTicketTime,
        takeoffTime: nodeDetail?.takeoffTime,
        ticketParPrice: nodeDetail?.totalAmount,
        policySource: nodeDetail?.policySource
      };
      const ticketRule = await tripService.queryFlightTicketRule(param);
      // console.log(ticketRule);
      nodeDetail.ticketRule = ticketRule;
      this.currentDetail = nodeDetail;
    } else {
      this.currentDetail = nodeDetail;
    }
    this.loading = false;
    if (this.currentItem.operationList) {
      this.currentDetail.operationList = [...this.currentItem.operationList];
    }
  },
  // 请求重新预定
  async requestTryNewTripNode(params) {
    const rsp = await tripService.queryTryNewTripNode(params);
    return rsp;
  }
};

export default {
  data() {
    return {
      currentItem: {},
      currentDetail: {},
      currentIndex: 0,
      currentParams: {},
      dataList: []
    };
  },
  watch: {
    currentItem(val) {
      const params = {
        id: val.id,
        tripNodeType: val.tripNodeType
      };
      this.requestNodeDetail(params);
    }
  },
  activated() {

  },
  computed: {

  },
  methods: {
    ...dataAndUIMethods,
    ...requestMethods,
    ...actionMethods
  }
};
