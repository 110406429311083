var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "trip-total-contain" }, [
    _c("span", { staticStyle: { color: "#636C78", "font-size": "12px" } }, [
      _vm._v("总价："),
    ]),
    _c("span", { staticStyle: { color: "#F78528", "font-size": "14px" } }, [
      _vm._v("¥ "),
    ]),
    _c(
      "span",
      {
        staticStyle: {
          color: "#F78528",
          "font-size": "16px",
          "font-weight": "500",
        },
      },
      [_vm._v(_vm._s(_vm.totalAmount))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }