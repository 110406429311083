<template>
  <div class="dialog-contain">
    <el-dialog
      title=""
      class="my-dialog"
      :show-close="false"
      :visible.sync="dialogRefundTicketVisible"
      append-to-body
    >
      <div class="body-contain">
        <span class="title">{{ isRefund ? "退票":"改签"}}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{ fill: '#A9B5C6' }"
          ></en-icon>
        </div>
        <div class="content-contain">
          <el-radio-group class="radio-group" v-model="radio">
            <el-radio :label="3">自愿</el-radio>
            <el-radio :label="6">非自愿</el-radio>
          </el-radio-group>
          <el-input
            v-model="inputText"
            class="text-area"
            type="textarea"
            resize="none"
            :placeholder="isRefund ? '请输入退票事由（必填）':'请输入改签事由（必填）'"
          ></el-input>
        </div>
        <div v-if="voluntarily" class="tips-text2">
          {{ isRefund ? '退票费用以航空公司实际收取为准':'改签费用以航空公司实际收取为准'}}
        </div>
        <div v-if="!voluntarily" class="tips-text">
          {{ '非自愿' + (isRefund ? '退票':'改签') + '仅限航班取消、延误等情况，有疑问请致电400-617-9001'}}
        </div>
        <div v-if="!voluntarily" class="xing-hao">*</div>
        <en-button class="confirm-button" @click="confirmClick">
          确定
        </en-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "RefundTicketDialog",
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    isRefund: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogRefundTicketVisible: true,
      inputText: "",
      radio: 3,
      voluntarily: true
    };
  },
  watch: {
    dialogRefundTicketVisible(val) {
      this.$emit("update:dialogVisible", val);
    },
    radio(val) {
      this.voluntarily = val === 3;
    }
  },
  methods: {
    // 关闭弹框
    closeClick() {
      this.dialogRefundTicketVisible = false;
    },
    // 确认已输入退票事由
    confirmClick() {
      if (this.inputText.length === 0) {
        this.$message(this.isRefund ? "请输入退票事由" : "请输入改签事由");
        return;
      }
      if (this.inputText.length > 200) {
        this.$message(this.isRefund ? "输入的退票事由在200字以内" : "输入的改签事由在200字以内");
        return;
      }

      this.dialogRefundTicketVisible = false;
      if (this.isRefund) {
        this.$emit("getRefundTicketValue", this.inputText, this.voluntarily);
      } else {
        this.$emit("getChangeTicketValue", this.inputText, this.voluntarily);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 700px;
  height: 360px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .content-contain {
    margin: 52px 20px 16px 20px;
    width: 660px;
    height: 234px;
    text-align: left;
    .radio-group {
      height: 31px;
      margin-top: 3px;
    }
    .text-area {
      &/deep/ .el-textarea__inner {
        padding: 5px 10px;
        height: 200px;
        background-color: #f5f7fa;
        border: 1px solid #e8ecf2;
        font-size: 12px;
      }
      &/deep/ .el-input__count {
        background-color: #f5f7fa;
      }
    }
  }
  .tips-text {
    position: absolute;
    left: 20px;
    bottom: 14px;
    width: 484px;
    height: 34px;
    line-height: 17px;
    font-size: 12px;
    text-align: left;
  }
  .tips-text2 {
    position: absolute;
    left: 20px;
    bottom: 29px;
    height: 13px;
    font-size: 12px;
    text-align: left;
  }
  .xing-hao {
    position: absolute;
    left: 12px;
    height: 12px;
    line-height: 12px;
    bottom: 32px;
    color: #f76b6b;
  }
  .confirm-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 72px;
    height: 32px;
  }
}
.my-dialog {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
.dialog-contain {
  & /deep/ .el-dialog__header {
    padding: 0;
    margin: 0;
    height: 0;
  }
  & /deep/ .el-dialog__body {
    padding: 0;
    margin: 0;
  }
}
</style>
