<!--商旅节点状态tag-->
<template>
  <div class="en-trip-node-status" :style="colorStyle">
    {{ statusName }}
  </div>
</template>

<script>
export default {
  name: "EnTripNodeStatus",
  props: {
    // 状态码
    status: Number,
    // 值为true时,为不透明状态背景色,字体白色
    isDark: Boolean
  },
  data() {
    return {};
  },
  computed: {
    // 节点状态
    // 000-未提交 001-订失败 002-预订中 003-已预订 004-已使用
    // 005-已提交 006-已取消 007-退票中 008-已退票 009-改签中
    // 010-已改签 011-已出票 012-出票中 013-已审批 014-未使用
    // 015-使用中 016-改派中 017-已退款
    statusName() {
      const statusValue = Number(this.status);
      const statusList = [
        "未提交", "订失败", "预订中", "已预订", "已使用",
        "已提交", "已取消", "退票中", "已退票", "改签中",
        "已改签", "已出票", "出票中", "已审批", "未使用",
        "使用中", "改派中", "已退款"
      ];
      return statusList[statusValue];
    },
    colorStyle() {
      let style = "background-color:rgba(38,195,147,0.08);color:#26C393;";
      const statusValue = Number(this.status);
      switch (statusValue) {
        case 0:
        case 14:
          // 未提交 未使用
          if (this.isDark) {
            style = "background-color:#3e90fe;";
          } else {
            style = "background-color:rgba(70,148,223,0.1);color:#3e90fe;";
          }

          break;
        case 1:
          // 订失败
          if (this.isDark) {
            style = "background-color:#F76B6B;";
          } else {
            style = "background-color:rgba(247,107,107,0.1);color:#F76B6B;";
          }

          break;
        case 2:
        case 7:
        case 9:
        case 12:
          // 预订中 退票中 改签中 出票中
          if (this.isDark) {
            style = "background-color:#FFAD2C;";
          } else {
            style = "background-color:rgba(255,173,44,0.1);color:#FFAD2C;";
          }

          break;
        case 3:
        case 5:
        case 10:
        case 11:
        case 13:
          // 已预订 已提交 已改签 已出票 已审批
          if (this.isDark) {
            style = "background-color:#26C393;";
          } else {
            style = "background-color:rgba(38,195,147,0.1);color:#26C393;";
          }

          break;
        case 4:
        case 6:
        case 8:
        case 17:
          // 已使用 已取消 已退票 已退款
          if (this.isDark) {
            style = "background-color:#636C78;";
          } else {
            style = "background-color:rgba(99,108,120,0.1);color:#636C78;";
          }

          break;
        case 15:
          // 使用中
          if (this.isDark) {
            style = "background-color:#FFAD2C;";
          } else {
            style = "background-color:rgba(255,173,44,0.1);color:#FFAD2C;";
          }

          break;
        case 16:
          // 改派中
          if (this.isDark) {
            style = "background-color:#FFAD2C;";
          } else {
            style = "background-color:rgba(255,173,44,0.1);color:#FFAD2C;";
          }

          break;
        default:
          break;
      }
      if (this.isDark) {
        style
          += "color:#fff;"
          + "border-radius:11px;"
          + "height:22px;"
          + "line-height:22px;";
      }
      return style;
    }
  },
  methods: {
    cssSytleString(styleList) {
      let style = "";
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in styleList) {
        style += `${key}:${styleList[key]};`;
      }
      return style;
    }
  }
};
</script>

<style lang="scss" scoped>
.en-trip-node-status {
  background-color: rgba(185, 196, 210, 0.08);
  display: inline-block;
  height: 26px;
  padding: 0 10px;
  line-height: 26px;
  font-size: 12px;
  color: #b9c4d2;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  // border-color: #ff000000;
  // border-width: 1px;
  // border-style: solid;
}
</style>
