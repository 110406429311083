<template>
  <div
    class="supplement-content-container"
    :style="contentHeightStyle"
  >
    <div class="supplement-top-contain">
      <span class="title">{{ titleText }}</span>
      <div class="top-content">
        <en-button
          v-if="detailData.shareUserList !== undefined && detailData.shareUserList.length > 0"
          type="text"
          icon-color="#FFAD2C"
          icon="gongxiang-wodehangcheng"
        >
          共享
        </en-button>
      </div>
      <div @click="closeClick">
        <en-icon
          name="guanbi-danchuang"
          class="close-icon"
          size="14px"
          :style="{fill:'#A9B5C6'}"
        ></en-icon>
      </div>
    </div>
    <div class="body-container">
      <div class="top-container">
        <detailTop
          :name="topName"
          :status="detailData.status"
        ></detailTop>

        <borderLine></borderLine>
        <detailNode :detail="detailNode"></detailNode>
        <borderLine></borderLine>

        <div class="top-bottom-contain">
          <en-icon
            name="budan-wodehangcheng"
            class="bu-icon"
            size="18px"
          ></en-icon>
          <tripTotalAmount
            class="total-amount"
            :total-amount="detailData.totalAmount"
          ></tripTotalAmount>
        </div>
      </div>
      <div class="bottom-container">
        <div class="bottom1-contain">
          <div class="title">
            订单信息
          </div>
        </div>
        <borderLine></borderLine>
        <div class="bottom2-contain">
          <div v-if="detailData.type === '000'">
            <detailLabel
              icon-name="renyuan-wodehangcheng"
              fill-color="#A9B5C6"
              name="乘机人员"
              :content="userName()"
              class="detail-label"
            />
            <detailLabel
              icon-name="cangweidengji-wodehangcheng"
              fill-color="#A9B5C6"
              name="舱位等级"
              content="经济舱"
              class="detail-label"
            />
          </div>
          <div v-else-if="detailData.type === '001'">
            <detailLabel
              icon-name="renyuan-wodehangcheng"
              fill-color="#A9B5C6"
              name="入住人员"
              :content="userName()"
              class="detail-label"
            />
            <detailLabel
              icon-name="daodachengshi-budan"
              fill-color="#A9B5C6"
              name="入住城市"
              content="长沙"
              class="detail-label"
            />
          </div>
          <div v-else-if="detailData.type === '002'">
            <detailLabel
              icon-name="renyuan-wodehangcheng"
              fill-color="#A9B5C6"
              name="乘车人员"
              :content="userName()"
              class="detail-label"
            />
            <detailLabel
              icon-name="cangweidengji-wodehangcheng"
              fill-color="#A9B5C6"
              name="乘坐席别"
              :content="seatName()"
              class="detail-label"
            />
          </div>
          <div v-else-if="detailData.type === '003'">
            <detailLabel
              icon-name="renyuan-wodehangcheng"
              fill-color="#A9B5C6"
              name="乘车人员"
              :content="userName()"
              class="detail-label"
            />
          </div>

          <div>
            <detailLabel
              v-if="detailData.type !== '004'"
              icon-name="shixiangshuoming-budan"
              fill-color="#A9B5C6"
              name="事项说明"
              :content="detailData.explain"
              class="detail-label"
            />
            <detailLabel
              v-if="detailData.standardMemo"
              icon-name="chaobiaoshiyou-wodehangcheng"
              fill-color="#F76B6B"
              name="超标事由"
              :content="detailData.standardMemo"
              class="detail-label"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import tripTotalAmount from "../../components/trip-total-amount";
import borderLine from "../components/border-line";
import detailTop from "../components/detail-top";
import detailLabel from "../components/detail-label";
import detailNode from "../components/detail-node";

export default {
  name: "SupplementNodeDetail",
  components: {
    borderLine,
    tripTotalAmount,
    detailLabel,
    detailNode,
    detailTop
  },
  props: {
    contentHeight: {
      type: Number,
      default: 800
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      contentHeightStyle: "",
      titleText: "",
      topName: "",
      detailNode: {},
      detailData: {},
      content: ""
    };
  },
  watch: {
    contentHeight(val) {
      this.contentHeightStyle = `max-height:${val}px;`;
    },
    detail() {
      this.updateDetailData();
    }
  },
  mounted() {
    this.contentHeightStyle = `max-height:${this.contentHeight}px;`;
    this.updateDetailData();
  },
  methods: {
    // 拼接显示用户的名字
    userName() {
      if (this.detailData.userList) {
        let names = "";
        for (let i = 0; i < this.detailData.userList.length; i++) {
          const user = this.detailData.userList[i];
          if (names.length === 0) {
            names = user.name;
          } else {
            names = `${names},${user.name}`;
          }
        }
        return names;
      }
      return "";
    },
    // 席别名称
    seatName() {
      const type = this.detailData.classType;
      if (type === "000") {
        return "经济舱";
      }
      if (type === "001") {
        return "公务舱";
      }
      if (type === "002") {
        return "头等舱";
      }
      if (type === "011") {
        return "商务座";
      }
      if (type === "012") {
        return "特等座";
      }
      if (type === "013") {
        return "一等座";
      }
      if (type === "014") {
        return "二等座";
      }
      if (type === "015") {
        return "软卧";
      }
      if (type === "016") {
        return "硬卧";
      }
      if (type === "018") {
        return "软座";
      }
      if (type === "019") {
        return "硬座";
      }
      return "";
    },
    updateDetailData() {
      this.detailData = this.detail;
      let obj = {};
      obj.leftTop = "出发地";
      obj.rightTop = "目的地";
      obj.leftBottom = this.detailData.startCity;
      obj.rightBottom = this.detailData.arrivalCity;

      if (this.detailData.type === "000") {
        this.titleText = "机票补单";
        this.topName = this.detailData.code;
        obj.leftTop = "出发城市";
        obj.rightTop = "到达城市";
        obj.center = "";
      } else if (this.detailData.type === "001") {
        this.titleText = "酒店补单";
        this.topName = this.detailData.name;
        obj.leftTop = "入住日期";
        obj.rightTop = "离店日期";
        obj.leftBottom = this.detailData.arrivalDate;
        obj.rightBottom = this.detailData.departureDate;
        const days = dayjs(this.detailData.departureDate).diff(dayjs(this.detailData.arrivalDate), "day");
        obj.center = `${days}晚`;
      } else if (this.detailData.type === "002") {
        this.titleText = "火车补单";
        this.topName = this.detailData.code;
        obj.center = "";
      } else if (this.detailData.type === "003") {
        this.titleText = "用车补单";
        this.topName = "";
        obj.center = "";
      } else if (this.detailData.type === "004") {
        this.titleText = "其他补单";
        this.topName = "其他";
        obj = { otherExplain: this.detailData.explain };
      }
      this.detailNode = obj;
    },
    closeClick() {
      this.$emit("closeClick");
    }
  }
};
</script>

<style lang="scss" scoped>
.supplement-content-container {
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  .supplement-top-contain {
    position: relative;
    height: 52px;
    .title {
      position: absolute;
      left: 20px;
      top: 19px;
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      text-align: left;
      color: #333;
    }
    .close-icon {
      position: absolute;
      right: 20px;
      top: 19px;
      width: 14px;
      height: 14px;
    }
    .top-content {
      position: absolute;
      right: 54px;
      top: 0;
      height: 52px;
      display: flex;
      align-items: right;
    }
  }
  .body-container {
    background: #f5f7fa;
    width: 100%;
    max-height: calc(100% - 52px);
    border-radius: 0 0 6px 6px;
    overflow-y: auto;
    .top-container {
      position: relative;
      margin: 0 20px;
      min-height: 200px;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 6px;
      border: 1px solid #e8ecf2;
      .top-bottom-contain {
        position: relative;
        width: 100%;
        min-height: 44px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        .bu-icon {
          position: absolute;
          top: 10px;
          left: 18px;
          fill: #3e90fe;
        }
        .total-amount {
          position: absolute;
          top: 10px;
          right: 18px;
        }
      }
    }
    .bottom-container {
      margin: 8px 20px 20px;
      width: calc(100% - 40px);
      min-height: 237px;
      border-radius: 6px;
      border: 1px solid #e8ecf2;
      background: #fff;
      .bottom1-contain {
        position: relative;
        height: 44px;
        width: 100%;
        .title {
          margin: 20px 0 0 20px;
          height: 12px;
          display: inline-block;
          color: #333;
        }
        .time {
          float: right;
          margin: 20px 0 0 20px;
          height: 12px;
          display: block;
          color: #91a1b7;
        }
        .button {
          float: right;
          width: 50px;
          height: 40px;
          display: block;
          .icon {
            float: right;
            margin: 16px 20px 0 0;
            fill: #3e90fe;
          }
        }
      }
      .bottom2-contain {
        width: 100%;
        border-radius: 0 0 6px 6px;
        padding-bottom: 10px;
        .detail-label {
          margin: 15px 20px 0;
        }
      }
    }
  }
}
</style>
