<template>
  <div
    class="car-content-container"
    :style="contentHeightStyle"
  >
    <div class="car-top-contain">
      <span class="title">用车详情</span>
      <div class="top-content">
        <!--
        <en-button
          type="text"
          icon-color="#FFAD2C"
          icon="gongxiang-wodehangcheng"
        >
          共享
        </en-button>
        -->
      </div>
      <div @click="closeClick">
        <en-icon
          name="guanbi-danchuang"
          class="close-icon"
          size="14px"
          :style="{fill:'#A9B5C6'}"
        ></en-icon>
      </div>
    </div>
    <div
      class="body-container"
      :style="operationShow ? 'max-height: calc(100% - 64px - 52px);':'max-height:calc(100% - 52px);'"
    >
      <div class="top-container">
        <detailTop
          :name="detailData.driverCard"
          :status="detailData.status"
        ></detailTop>

        <borderLine></borderLine>
        <detailNode :detail="detailNode"></detailNode>
        <borderLine></borderLine>

        <div class="top-bottom-contain">
          <div class="tuigai-top-contain">
            <tripTotalAmount
              slot="reference"
              class="total-amount"
              :total-amount="detailData.totalAmount"
            ></tripTotalAmount>
            <!-- <el-popover
              popper-class="custom-car-popover"
              placement="bottom-end"
              trigger="hover"
            >
              <div class="amount-car-popover">
                <div class="line-container">
                  <span class="content">起步价</span>
                  <span class="content">¥8.00</span>
                </div>
                <div class="line-container">
                  <span class="content">企业实付</span>
                  <span class="content">¥18.00</span>
                </div>
                <div class="line-container">
                  <span class="content">企业应付</span>
                  <span class="content">¥18.00</span>
                </div>
                <div style="background-color:#E8ECF2;height:1px;margin:5px 0 7px 0;" />
                <div class="tips-container">
                  <en-icon
                    size="12px"
                    name="xiaoxi"
                    :style="{fill:'#fff'}"
                  ></en-icon>
                  <span class="last-content">若您的车费有误，可拨打400-617-9001进行反馈</span>
                </div>
              </div>
              <tripTotalAmount
                slot="reference"
                class="total-amount"
                :total-amount="detailData.totalAmount"
              ></tripTotalAmount>
            </el-popover> -->
          </div>
        </div>
      </div>
      <div
        class="bottom-container"
      >
        <div class="bottom1-contain">
          <div class="title">
            {{ orderNumberDetail }}
          </div>
          <div
            class="relation-button"
            v-if="detailData.tripId"
            onClick="event.cancelBubble = true"
            @click="relationTripClick"
          >
            <en-icon
              name="guanlian-wodehangcheng"
              size="16px"
              class="icon"
            ></en-icon>
          </div>
          <div class="time">
            {{ detailData.createTime }}
          </div>
        </div>
        <borderLine></borderLine>
        <div class="bottom2-contain">
          <detailLabel
            icon-name="renyuan-wodehangcheng"
            fill-color="#A9B5C6"
            name="乘车人员"
            :content="passengerNames()"
            class="detail-label"
          />
          <detailLabel
            v-if="detailData.driverName"
            icon-name="jiachesiji-wodehangcheng"
            fill-color="#A9B5C6"
            name="驾车司机"
            :content="detailData.driverName"
            class="detail-label"
          />
          <detailLabel
            v-if="carDetail.length > 0"
            icon-name="cheliangxinxi-wodehangcheng"
            fill-color="#A9B5C6"
            name="车辆信息"
            :content="carDetail"
            class="detail-label"
          />
          <detailLabel
            v-if="detailData.driverPhone"
            icon-name="lianxidianhua-wodehangcheng"
            fill-color="#A9B5C6"
            name="联系电话"
            :content="detailData.driverPhone"
            class="detail-label"
          />
          <detailLabel
            v-if="detailData.standardMemo"
            icon-name="chaobiaoshiyou-wodehangcheng"
            fill-color="#F76B6B"
            name="超标事由"
            :content="detailData.standardMemo"
            class="detail-label"
          />
        </div>

        <div v-if="!!changeList && changeList.length > 0">
          <borderLine></borderLine>
          <div class="bottom3-contain">
            <div
              class="biangeng"
              onClick="event.cancelBubble = true"
              @click="biangengOpen = !biangengOpen"
            >
              <span
                class="text"
                :style="biangengOpen ? 'color:#3e90fe;':''"
              >变更详情</span>
              <en-icon
                class="jiantou"
                size="9px"
                name="zhankai"
                :style="biangengOpen ? 'transform: rotate(180deg);':''"
              ></en-icon>
            </div>
            <div
              v-if="biangengOpen"
              class="biangeng-item-contain"
            >
              <div
                v-for="item in changeList"
                :key="item.id"
                class="biangeng-item"
              >
                <span class="type">{{ changeStatusDetail(item.changeStatus) }}</span>
                <span class="time">{{ item.createTime }}</span>
                <span class="reason">{{ "取消事由：" + item.changeReason }}</span>
                <span class="result">{{ "处理结果：" + item.changeResult }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="operationShow"
      class="operation-contain"
    >
      <div v-if="detailData.shareUserList" class="tips-contain">
        <en-icon
          name="bitian"
          size="16px"
          :style="{fill:'#F7BF27'}"
        ></en-icon>
        <div class="tips">
          {{ shareUserName() }}
        </div>
      </div>
      <en-button
        v-if="againBookButton"
        class="button"
        @click="againButtonClick"
      >
        再次预约
      </en-button>
    </div>
  </div>
</template>

<script>
import tripTotalAmount from "../../components/trip-total-amount";
import borderLine from "../components/border-line";
import detailTop from "../components/detail-top";
import detailLabel from "../components/detail-label";
import detailNode from "../components/detail-node";

export default {
  name: "CarNodeDetail",
  components: {
    borderLine,
    tripTotalAmount,
    detailLabel,
    detailNode,
    detailTop
  },
  props: {
    contentHeight: {
      type: Number,
      default: 800
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      contentHeightStyle: "",
      bottomContainerStyle: "min-height: 237px;",
      biangengOpen: true,
      detailNode: {},
      detailData: {},
      tripNodeId: "",
      changeList: [],
      content: ""
    };
  },
  watch: {
    contentHeight(val) {
      this.contentHeightStyle = `max-height:${val}px;`;
    },
    detail() {
      this.updateDetailData();
    }
  },
  mounted() {
    this.contentHeightStyle = `max-height:${this.contentHeight}px;`;
  },
  computed: {
    againBookButton() {
      if ((this.detailData.tripStatus === "003"
        || this.detailData.tripStatus === "004"
        || this.detailData.tripStatus === "005")
        && this.detailData.status === "001") {
        return true;
      }
      return false;
    },
    carDetail() {
      if (this.detailData.driverCarType && this.detailData.driverCarColor && this.detailData.driverCard) {
        return `${this.carLevelName}-${this.detailData.driverCarType}-${this.detailData.driverCarColor}-${this.detailData.driverCard}`;
      }
      return this.carLevelName;
    },
    carLevelName() {
      if (this.detailData.requireLevel === "100") {
        return "舒适车型";
      }
      if (this.detailData.requireLevel === "400") {
        return "商务车型";
      }
      if (this.detailData.requireLevel === "200") {
        return "豪华车型";
      }
      if (this.detailData.requireLevel === "500") {
        return "优选车型";
      }
      if (this.detailData.requireLevel === "600") {
        if (this.detailData.carType === "1") {
          return "经济车型";
        }
        return "快车车型";
      }
      return "";
    },
    orderNumberDetail() {
      if (this.detailData.orderId) {
        return `订单编号：${this.detailData.orderId}`;
      }
      return "订单编号：";
    },
    operationShow() {
      return this.detailData.shareUserList || this.againBookButton;
    }
  },
  methods: {
    // 乘车人
    passengerNames() {
      if (this.detailData.passengerName) {
        return this.detailData.passengerName;
      }
      if (this.detailData.userList) {
        let names = "";
        for (let i = 0; i < this.detailData.userList.length; i++) {
          const user = this.detailData.userList[i];
          if (names.length === 0) {
            names = user.name;
          } else {
            names = `${names},${user.name}`;
          }
        }
        return names;
      }
      return "";
    },
    // 共享人
    shareUserName() {
      if (this.detailData.shareUserList) {
        const user = this.detailData.shareUserList[0];
        return `由${user.name}共享`;
      }
      return "";
    },
    // 变更状态
    changeStatusDetail(status) {
      if (status === "000") {
        return "改签成功";
      }
      if (status === "001") {
        return "改签成功";
      }
      if (status === "002") {
        return "改签失败";
      }
      if (status === "003") {
        return "退票成功";
      }
      if (status === "004") {
        return "退票失败";
      }
      if (status === "005") {
        return "预订失败";
      }
      if (status === "006") {
        return "退房成功";
      }
      if (status === "007") {
        return "延期事由";
      }
      if (status === "008") {
        return "退房失败";
      }
      if (status === "009") {
        return "即时叫车";
      }
      if (status === "010") {
        return "预约失败";
      }
      if (status === "011") {
        return "取消用车";
      }
      if (status === "012") {
        return "重新预订";
      }
      if (status === "013") {
        return "移动成功";
      }
      return "";
    },
    updateDetailData() {
      this.detailData = this.detail;
      this.tripNodeId = this.detailData.id;

      const obj = {};
      obj.leftTop = "出发地";
      obj.rightTop = "目的地";
      obj.leftBottom = this.detailData.startName;
      obj.rightBottom = this.detailData.endName;
      obj.center = this.detailData.departureTime;
      this.detailNode = obj;

      if (this.detailData.changeInfos) {
        this.changeList = [...this.detailData.changeInfos];
      }
    },
    closeClick() {
      this.$emit("closeClick");
    },
    relationTripClick() {
      // this.$message("关联行程");
      const newRou = this.$router.resolve({
        path: "/business-travel/trip/tripDetail",
        query: { tripId: this.detailData.tripId }
      });
      window.open(newRou.href, "_blank");
    },
    // 再次预约
    againButtonClick() {
      this.closeClick();
      this.$router.push({
        path: "/business-travel/car",
        query: { tripId: this.detailData.tripId }
      });
    }
  }
};
</script>

<style lang="scss">
.el-popper[x-placement^="bottom"] {
  .popper__arrow {
    &::after {
      top: 0 !important;
    }
  }
}
.el-popper[x-placement^="top"] {
  .popper__arrow {
    &::after {
      bottom: 0 !important;
    }
  }
}
.custom-car-popover {
  .popper__arrow {
    &::after {
      // border-top-color: orange !important;
      // border-bottom-color: orange !important;
      border-top-color: #666975 !important;
      border-bottom-color: #666975 !important;
    }
  }
  .amount-car-popover {
    margin: -13px;
    padding: 12px;
    // background-color: orange;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    height: 105px;
    min-width: 267px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    .line-container {
      display: flex;
      justify-content: space-between;
    }
    .tips-container {
      display: flex;
    }
    .content {
      display: block;
      padding-bottom: 2px;
      color: #fff;
    }
    .last-content {
      color: #fff;
      margin-left: 3px;
    }
  }
}
</style>

<style lang="scss" scoped>
.car-content-container {
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  .car-top-contain {
    position: relative;
    height: 52px;
    .title {
      position: absolute;
      left: 20px;
      top: 19px;
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      text-align: left;
      color: #333;
    }
    .close-icon {
      position: absolute;
      right: 20px;
      top: 19px;
      width: 14px;
      height: 14px;
    }
    .top-content {
      position: absolute;
      right: 54px;
      top: 0;
      height: 52px;
      display: flex;
      align-items: right;
    }
  }
  .body-container {
    background: #f5f7fa;
    width: 100%;
    max-height: calc(100% - 52px);
    border-radius: 0 0 6px 6px;
    overflow-y: auto;
    .top-container {
      position: relative;
      margin: 0 20px;
      min-height: 230px;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 6px;
      border: 1px solid #e8ecf2;

      .top-bottom-contain {
        width: 100%;
        min-height: 44px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .tuigai-top-contain {
          height: 44px;
          position: relative;
          .total-amount {
            position: absolute;
            top: 10px;
            right: 18px;
          }
        }
      }
    }
    .bottom-container {
      margin: 8px 20px 20px;
      width: calc(100% - 40px);
      min-height: 200px;
      border-radius: 6px;
      border: 1px solid #e8ecf2;
      background: #fff;
      .bottom1-contain {
        position: relative;
        height: 44px;
        width: 100%;
        padding-right:10px;
        .title {
          margin: 20px 0 0 20px;
          height: 12px;
          display: inline-block;
          color: #333;
        }
        .time {
          float: right;
          margin: 20px 0 0 20px;
          height: 12px;
          display: block;
          color: #91a1b7;
        }
        .relation-button {
          float: right;
          width: 30px;
          height: 40px;
          display: block;
          cursor: pointer;
          .icon {
            float: right;
            margin: 16px 0 0 0;
            fill: #3e90fe;
          }
        }
      }
      .bottom2-contain {
        width: 100%;
        border-radius: 0 0 6px 6px;
        padding-bottom: 10px;
        .detail-label {
          margin: 15px 20px 0;
        }
      }
      .bottom3-contain {
        position: relative;
        height: auto;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-bottom: 10px;
        .biangeng {
          margin-left: 18px;
          padding-top: 8px;
          height: 38px;
          display: flex;
          align-items: center;
          .text {
            color: #333;
          }
          .jiantou {
            margin-left: 10px;
            fill: #a9b5c6;
          }
        }
        .biangeng-item-contain {
          margin: 6px 20px 18px;
          .biangeng-item {
            position: relative;
            min-height: 80px;
            margin-bottom: 8px;
            padding: 11px 12px;
            border-radius: 4px;
            border: 1px solid #e8ecf2;
            background-color: #f6fafd;
            .type {
              position: absolute;
              left: 12px;
              height: 13px;
              color: #333;
            }
            .time {
              position: absolute;
              right: 12px;
              height: 13px;
              color: #91a1b7;
            }
            .reason {
              display: block;
              margin-top: 20px;
              left: 12px;
              min-height: 13px;
              color: #636c78;
            }
            .result {
              display: block;
              margin-top: 6px;
              left: 12px;
              min-height: 13px;
              color: #636c78;
            }
          }
        }
      }
    }
  }
  .operation-contain {
    position: relative;
    height: 64px;
    padding: 0 20px;
    border-top: 1px solid #e8ecf2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button {
      min-width: 72px;
      height: 32px;
    }
    & /deep/ .el-button--small {
      padding-left: 12px;
    }
    .tips-contain {
      position: absolute;
      min-width: 100px;
      height: 60px;
      top: 0;
      left: 20px;
      display: flex;
      align-items: center;
      .tips {
        padding-left: 6px;
        color: #636c78;
      }
    }
  }
}
</style>
