var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flight-content-container", style: _vm.contentHeightStyle },
    [
      _c("div", { staticClass: "flight-top-contain" }, [
        _c("span", { staticClass: "title" }, [_vm._v("机票详情")]),
        _c("div", { staticClass: "top-content" }),
        _c(
          "div",
          { on: { click: _vm.closeClick } },
          [
            _c("en-icon", {
              staticClass: "close-icon",
              style: { fill: "#A9B5C6" },
              attrs: { name: "guanbi-danchuang", size: "14px" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "body-container",
          style: _vm.operationShow
            ? "max-height: calc(100% - 64px - 52px);"
            : "max-height:calc(100% - 52px);",
        },
        [
          _c(
            "div",
            { staticClass: "top-container" },
            [
              _c("detailTop", {
                attrs: {
                  icon: "bitian",
                  name: _vm.flightNoDetail(),
                  status: _vm.detailData.status,
                },
              }),
              _c("borderLine"),
              _c("detailNode", { attrs: { detail: _vm.detailNode } }),
              _c("borderLine", { attrs: { "bottom-gray": _vm.tuigaiOpen } }),
              _c(
                "div",
                {
                  staticClass: "top-bottom-contain",
                  style: _vm.tuigaiOpen
                    ? "background-color:#FBFBFC;height:auto;"
                    : "",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tuigai",
                      on: {
                        click: function ($event) {
                          _vm.tuigaiOpen = !_vm.tuigaiOpen
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text",
                          style: _vm.tuigaiOpen ? "color:#3e90fe;" : "",
                        },
                        [_vm._v("退改规则")]
                      ),
                      _c("en-icon", {
                        staticClass: "jiantou",
                        style: _vm.tuigaiOpen
                          ? "transform: rotate(180deg);"
                          : "",
                        attrs: { size: "9px", name: "zhankai" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-flight-popover",
                        placement: "bottom-end",
                        trigger: "hover",
                      },
                    },
                    [
                      _c("div", { staticClass: "amount-flight-popover" }, [
                        _c("div", { staticClass: "line-container" }, [
                          _c("span", { staticClass: "content" }, [
                            _vm._v("票价格"),
                          ]),
                          _c("span", { staticClass: "content" }, [
                            _vm._v("¥" + _vm._s(_vm.detailData.salePrice)),
                          ]),
                        ]),
                        _c("div", { staticClass: "line-container" }, [
                          _c("span", { staticClass: "content" }, [
                            _vm._v("机建燃油"),
                          ]),
                          _c("span", { staticClass: "content" }, [
                            _vm._v(
                              "¥" +
                                _vm._s(
                                  Number(_vm.detailData.buildFee) +
                                    Number(_vm.detailData.fuelFee)
                                )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tripTotalAmount", {
                        staticClass: "total-amount",
                        attrs: {
                          slot: "reference",
                          "total-amount": _vm.detailData.totalAmount,
                        },
                        slot: "reference",
                      }),
                    ],
                    1
                  ),
                  _vm.tuigaiOpen
                    ? _c(
                        "div",
                        { staticClass: "tuigai-contain" },
                        [
                          _vm.tuigaiDataList.length > 1
                            ? _c("en-tabs", {
                                attrs: { list: _vm.tabList, type: "white" },
                                on: { change: _vm.changeTabs },
                                model: {
                                  value: _vm.activeTab,
                                  callback: function ($$v) {
                                    _vm.activeTab = $$v
                                  },
                                  expression: "activeTab",
                                },
                              })
                            : _c("div", { staticStyle: { height: "12px" } }),
                          _c(
                            "el-table",
                            {
                              staticClass: "tuigaiTable",
                              staticStyle: { width: "100%" },
                              attrs: {
                                border: "",
                                data: _vm.tuigaiTableData,
                                "show-header": false,
                                "cell-style": _vm.cellStyle,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "type" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "column1" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "column2" },
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "column3" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticStyle: { height: "20px" } }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-container", on: { click: _vm.editClick } },
            [
              _c("div", { staticClass: "bottom1-contain" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(_vm.orderNumberDetail) + " "),
                ]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(" " + _vm._s(_vm.orderDate()) + " "),
                ]),
              ]),
              _c("borderLine"),
              _c(
                "div",
                { staticClass: "bottom2-contain" },
                [
                  _c("detailLabel", {
                    staticClass: "detail-label",
                    attrs: {
                      "icon-name": "renyuan-wodehangcheng",
                      "fill-color": "#A9B5C6",
                      name: "乘机人员",
                      content: _vm.passengerName(),
                    },
                  }),
                  _c("detailLabel", {
                    staticClass: "detail-label",
                    attrs: {
                      "icon-name": "cangweidengji-wodehangcheng",
                      "fill-color": "#A9B5C6",
                      name: "舱位等级",
                      content: _vm.cabinName(),
                    },
                  }),
                  _vm.detailData.explain
                    ? _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "shixiangshuoming-budan",
                          "fill-color": "#A9B5C6",
                          name: "事项说明",
                          content: _vm.detailData.explain,
                        },
                      })
                    : _vm._e(),
                  _vm.detailData.standardMemo
                    ? _c("detailLabel", {
                        staticClass: "detail-label",
                        attrs: {
                          "icon-name": "chaobiaoshiyou-wodehangcheng",
                          "fill-color": "#F76B6B",
                          name: "超标事由",
                          content: _vm.detailData.standardMemo,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              !!_vm.changeList && _vm.changeList.length > 0
                ? _c(
                    "div",
                    [
                      _c("borderLine"),
                      _c("div", { staticClass: "bottom3-contain" }, [
                        _c(
                          "div",
                          {
                            staticClass: "biangeng",
                            attrs: { onClick: "event.cancelBubble = true" },
                            on: {
                              click: function ($event) {
                                _vm.biangengOpen = !_vm.biangengOpen
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "text",
                                style: _vm.biangengOpen ? "color:#3e90fe;" : "",
                              },
                              [_vm._v("变更详情")]
                            ),
                            _c("en-icon", {
                              staticClass: "jiantou",
                              style: _vm.biangengOpen
                                ? "transform: rotate(180deg);"
                                : "",
                              attrs: { size: "9px", name: "zhankai" },
                            }),
                          ],
                          1
                        ),
                        _vm.biangengOpen
                          ? _c(
                              "div",
                              { staticClass: "biangeng-item-contain" },
                              _vm._l(_vm.changeList, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "biangeng-item",
                                  },
                                  [
                                    _c("span", { staticClass: "type" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.changeStatusDetail(
                                            item.changeStatus
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(_vm._s(item.createTime)),
                                    ]),
                                    _c("div", { staticClass: "reason-block" }, [
                                      item.changeReason
                                        ? _c(
                                            "span",
                                            { staticClass: "reason" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "退票事由：" +
                                                    item.changeReason
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", { staticClass: "result" }, [
                                        _vm._v(
                                          _vm._s(
                                            "处理结果：" + item.changeResult
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.operationShow
        ? _c(
            "div",
            { staticClass: "operation-contain" },
            [
              _vm.detailData.shareUserList
                ? _c(
                    "div",
                    { staticClass: "tips-contain" },
                    [
                      _c("en-icon", {
                        style: { fill: "#F7BF27" },
                        attrs: { name: "bitian", size: "16px" },
                      }),
                      _c("div", { staticClass: "tips" }, [
                        _vm._v(" " + _vm._s(_vm.shareUserName()) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tuiButton
                ? _c(
                    "en-button",
                    {
                      staticClass: "button",
                      attrs: { type: "withe" },
                      on: { click: _vm.tuiButtonClick },
                    },
                    [_vm._v(" 退票 ")]
                  )
                : _vm._e(),
              _vm.gaiButton
                ? _c(
                    "en-button",
                    {
                      staticClass: "button",
                      on: { click: _vm.gaiButtonClick },
                    },
                    [_vm._v(" 改签 ")]
                  )
                : _vm._e(),
              _vm.reBookButton
                ? _c(
                    "en-button",
                    {
                      staticClass: "button",
                      on: { click: _vm.reBookButtonClick },
                    },
                    [_vm._v(" 重新预定 ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.rTicketDialogShow
        ? _c("rTicketDialog", {
            attrs: {
              "dialog-visible": _vm.rTicketDialogShow,
              "is-refund": _vm.isRefund,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.rTicketDialogShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.rTicketDialogShow = $event
              },
              getRefundTicketValue: _vm.getRefundTicketValue,
              getChangeTicketValue: _vm.getChangeTicketValue,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }