var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hotel-content-container", style: _vm.contentHeightStyle },
    [
      _c("div", { staticClass: "hotel-top-contain" }, [
        _c("span", { staticClass: "title" }, [_vm._v("酒店详情")]),
        _c("div", { staticClass: "top-content" }),
        _c(
          "div",
          { on: { click: _vm.closeClick } },
          [
            _c("en-icon", {
              staticClass: "close-icon",
              style: { fill: "#A9B5C6" },
              attrs: { name: "guanbi-danchuang", size: "14px" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "body-container",
          style: _vm.operationShow
            ? "max-height: calc(100% - 64px - 52px);"
            : "max-height:calc(100% - 52px);",
        },
        [
          _c(
            "div",
            { staticClass: "top-container" },
            [
              _c("detailTop", {
                attrs: {
                  name: _vm.detailData.hotelName,
                  status: _vm.detailData.status,
                },
              }),
              _c("borderLine"),
              _c("detailNode", { attrs: { detail: _vm.detailEntity } }),
              _c("borderLine"),
              _c(
                "div",
                {
                  staticClass: "top-bottom-contain",
                  style: _vm.tuigaiOpen ? "min-height:92px;" : "",
                },
                [
                  _c(
                    "div",
                    { staticClass: "tuigai-top-contain" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tuigai",
                          on: {
                            click: function ($event) {
                              _vm.tuigaiOpen = !_vm.tuigaiOpen
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text",
                              style: _vm.tuigaiOpen ? "color:#3e90fe;" : "",
                            },
                            [_vm._v("退改规则")]
                          ),
                          _c("en-icon", {
                            staticClass: "jiantou",
                            style: _vm.tuigaiOpen
                              ? "transform: rotate(180deg);"
                              : "",
                            attrs: { size: "9px", name: "zhankai" },
                          }),
                        ],
                        1
                      ),
                      _c("tripTotalAmount", {
                        staticClass: "total-amount",
                        attrs: { "total-amount": _vm.detailData.totalAmount },
                      }),
                    ],
                    1
                  ),
                  _vm.tuigaiOpen
                    ? _c("div", { staticClass: "tuigai-contain" }, [
                        _vm._v(" " + _vm._s(_vm.content) + " "),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-container", on: { click: _vm.editClick } },
            [
              _c("div", { staticClass: "bottom1-contain" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(" " + _vm._s(_vm.orderNumberDetail) + " "),
                ]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(" " + _vm._s(_vm.orderDate()) + " "),
                ]),
              ]),
              _c("borderLine"),
              _c(
                "div",
                { staticClass: "bottom2-contain" },
                [
                  _c("detailLabel", {
                    staticClass: "detail-label",
                    attrs: {
                      "icon-name": "renyuan-wodehangcheng",
                      "fill-color": "#A9B5C6",
                      name: "入住人员",
                      content: _vm.passengerName(),
                    },
                  }),
                  _c("detailLabel", {
                    staticClass: "detail-label",
                    attrs: {
                      "icon-name": "wodejiudian-yongcheyuding",
                      "fill-color": "#A9B5C6",
                      name: "房型标准",
                      content: _vm.detailData.roomTypeName,
                    },
                  }),
                  _c("detailLabel", {
                    staticClass: "detail-label",
                    attrs: {
                      "icon-name": "lianxidianhua-wodehangcheng",
                      "fill-color": "#A9B5C6",
                      name: "联系电话",
                      content: _vm.detailData.phone,
                    },
                  }),
                  _c("detailLabel", {
                    staticClass: "detail-label",
                    attrs: {
                      "icon-name": "zuiwandaodian-wodehangcheng",
                      "fill-color": "#A9B5C6",
                      name: "最晚到店",
                      content: _vm.detailData.latestArrivalTime,
                    },
                  }),
                  _c("detailLabel", {
                    staticClass: "detail-label",
                    attrs: {
                      "icon-name": "jiudiandizhi-wodehangcheng",
                      "fill-color": "#A9B5C6",
                      name: "酒店地址",
                      content: _vm.detailData.address,
                    },
                  }),
                ],
                1
              ),
              !!_vm.changeList && _vm.changeList.length > 0
                ? _c(
                    "div",
                    [
                      _c("borderLine"),
                      _c("div", { staticClass: "bottom3-contain" }, [
                        _c(
                          "div",
                          {
                            staticClass: "biangeng",
                            attrs: { onClick: "event.cancelBubble = true" },
                            on: {
                              click: function ($event) {
                                _vm.biangengOpen = !_vm.biangengOpen
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "text",
                                style: _vm.biangengOpen ? "color:#3e90fe;" : "",
                              },
                              [_vm._v("变更详情")]
                            ),
                            _c("en-icon", {
                              staticClass: "jiantou",
                              style: _vm.biangengOpen
                                ? "transform: rotate(180deg);"
                                : "",
                              attrs: { size: "9px", name: "zhankai" },
                            }),
                          ],
                          1
                        ),
                        _vm.biangengOpen
                          ? _c(
                              "div",
                              { staticClass: "biangeng-item-contain" },
                              _vm._l(_vm.changeList, function (item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "biangeng-item",
                                  },
                                  [
                                    _c("span", { staticClass: "type" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.changeStatusDetail(
                                            item.changeStatus
                                          )
                                        )
                                      ),
                                    ]),
                                    _c("span", { staticClass: "time" }, [
                                      _vm._v(_vm._s(item.createTime)),
                                    ]),
                                    _c("div", { staticClass: "reason-block" }, [
                                      item.changeReason
                                        ? _c(
                                            "span",
                                            { staticClass: "reason" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  "取消事由：" +
                                                    item.changeReason
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("span", { staticClass: "result" }, [
                                        _vm._v(
                                          _vm._s(
                                            "处理结果：" + item.changeResult
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.operationShow
        ? _c(
            "div",
            { staticClass: "operation-contain" },
            [
              _vm.detailData.shareUserList
                ? _c(
                    "div",
                    { staticClass: "tips-contain" },
                    [
                      _c("en-icon", {
                        style: { fill: "#F7BF27" },
                        attrs: { name: "bitian", size: "16px" },
                      }),
                      _c("div", { staticClass: "tips" }, [
                        _vm._v(" " + _vm._s(_vm.shareUserName()) + " "),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reBookButton
                ? _c(
                    "en-button",
                    {
                      staticClass: "button",
                      on: { click: _vm.reBookButtonClick },
                    },
                    [_vm._v(" 重新预定 ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }