var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-contain" },
    [
      _c("en-icon", {
        staticClass: "icon",
        style: "fill:" + _vm.fillColor + ";",
        attrs: { name: _vm.iconName },
      }),
      _c("div", { staticClass: "name" }, [
        _vm._v(" " + _vm._s(_vm.name + "：") + " "),
      ]),
      _c("div", { staticClass: "content" }, [
        _vm._v(" " + _vm._s(_vm.content) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }