var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-contain" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "my-dialog",
          attrs: {
            title: "",
            "show-close": false,
            visible: _vm.dialogRefundTicketVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRefundTicketVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "body-contain" },
            [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.isRefund ? "退票" : "改签")),
              ]),
              _c(
                "div",
                { on: { click: _vm.closeClick } },
                [
                  _c("en-icon", {
                    staticClass: "close-icon",
                    style: { fill: "#A9B5C6" },
                    attrs: { name: "guanbi-danchuang", size: "14px" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content-contain" },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "radio-group",
                      model: {
                        value: _vm.radio,
                        callback: function ($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("自愿")]),
                      _c("el-radio", { attrs: { label: 6 } }, [
                        _vm._v("非自愿"),
                      ]),
                    ],
                    1
                  ),
                  _c("el-input", {
                    staticClass: "text-area",
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      placeholder: _vm.isRefund
                        ? "请输入退票事由（必填）"
                        : "请输入改签事由（必填）",
                    },
                    model: {
                      value: _vm.inputText,
                      callback: function ($$v) {
                        _vm.inputText = $$v
                      },
                      expression: "inputText",
                    },
                  }),
                ],
                1
              ),
              _vm.voluntarily
                ? _c("div", { staticClass: "tips-text2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.isRefund
                            ? "退票费用以航空公司实际收取为准"
                            : "改签费用以航空公司实际收取为准"
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              !_vm.voluntarily
                ? _c("div", { staticClass: "tips-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          "非自愿" +
                            (_vm.isRefund ? "退票" : "改签") +
                            "仅限航班取消、延误等情况，有疑问请致电400-617-9001"
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              !_vm.voluntarily
                ? _c("div", { staticClass: "xing-hao" }, [_vm._v("*")])
                : _vm._e(),
              _c(
                "en-button",
                {
                  staticClass: "confirm-button",
                  on: { click: _vm.confirmClick },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }