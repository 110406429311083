<template>
  <div class="border-line-contain">
    <div
      v-if="topGray"
      class="top-bg"
    ></div>
    <div
      v-if="bottomGray"
      class="bottom-bg"
    ></div>
    <div class="left-border"></div>
    <div class="center-line"></div>
    <div class="right-border"></div>
  </div>
</template>

<script>
export default {
  name: "BorderLine",
  props: {
    topGray: {
      type: Boolean,
      default: false
    },
    bottomGray: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.border-line-contain {
  position: relative;
  margin: 0 -1px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    z-index: 0;
  }
  .bottom-bg {
    position: absolute;
    top: 5px;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: #fbfbfc;
    z-index: 0;
  }
  .left-border {
    flex-shrink: 0;
    width: 5px;
    height: 10px;
    border: 1px solid #e8ecf2;
    border-left-color: #f5f7fa;
    background-color: #f5f7fa;
    border-radius: 0 10px 10px 0;
    z-index: 1;
  }
  .right-border {
    flex-shrink: 0;
    width: 5px;
    height: 10px;
    border: 1px solid #e8ecf2;
    border-right-color: #f5f7fa;
    background-color: #f5f7fa;
    border-radius: 10px 0 0 10px;
    z-index: 1;
  }
  .center-line {
    flex-shrink: 1;
    height: 1px;
    margin: 0 10px;
    width: calc(100% - 40px);
    background-size: 8px 1px;
    background-repeat: repeat-x;
    background-image: linear-gradient(to right, #e8ecf2 60%, transparent 40%);
    z-index: 1;
  }
}
</style>
