<template>
  <div
    class="hotel-content-container"
    :style="contentHeightStyle"
  >
    <div class="hotel-top-contain">
      <span class="title">酒店详情</span>
      <div class="top-content">
        <!--
        <en-button
          type="text"
          icon-color="#FFAD2C"
          icon="gongxiang-wodehangcheng"
        >
          共享
        </en-button>
        -->
      </div>
      <div @click="closeClick">
        <en-icon
          name="guanbi-danchuang"
          class="close-icon"
          size="14px"
          :style="{fill:'#A9B5C6'}"
        ></en-icon>
      </div>
    </div>
    <div
      class="body-container"
      :style="operationShow ? 'max-height: calc(100% - 64px - 52px);':'max-height:calc(100% - 52px);'"
    >
      <div class="top-container">
        <detailTop
          :name="detailData.hotelName"
          :status="detailData.status"
        ></detailTop>

        <borderLine></borderLine>
        <detailNode :detail="detailEntity"></detailNode>
        <borderLine></borderLine>

        <div
          class="top-bottom-contain"
          :style="tuigaiOpen ? 'min-height:92px;':''"
        >
          <div class="tuigai-top-contain">
            <div
              class="tuigai"
              @click="tuigaiOpen = !tuigaiOpen"
            >
              <span
                class="text"
                :style="tuigaiOpen ? 'color:#3e90fe;':''"
              >退改规则</span>
              <en-icon
                class="jiantou"
                size="9px"
                name="zhankai"
                :style="tuigaiOpen ? 'transform: rotate(180deg);':''"
              ></en-icon>
            </div>
            <tripTotalAmount
              class="total-amount"
              :total-amount="detailData.totalAmount"
            ></tripTotalAmount>
          </div>

          <div
            v-if="tuigaiOpen"
            class="tuigai-contain"
          >
            {{ content }}
          </div>
        </div>
      </div>
      <div
        class="bottom-container"
        @click="editClick"
      >
        <div class="bottom1-contain">
          <div class="title">
            {{ orderNumberDetail }}
          </div>
          <div class="time">
            {{ orderDate() }}
          </div>
        </div>
        <borderLine></borderLine>
        <div class="bottom2-contain">
          <detailLabel
            icon-name="renyuan-wodehangcheng"
            fill-color="#A9B5C6"
            name="入住人员"
            :content="passengerName()"
            class="detail-label"
          />
          <detailLabel
            icon-name="wodejiudian-yongcheyuding"
            fill-color="#A9B5C6"
            name="房型标准"
            :content="detailData.roomTypeName"
            class="detail-label"
          />
          <detailLabel
            icon-name="lianxidianhua-wodehangcheng"
            fill-color="#A9B5C6"
            name="联系电话"
            :content="detailData.phone"
            class="detail-label"
          />
          <detailLabel
            icon-name="zuiwandaodian-wodehangcheng"
            fill-color="#A9B5C6"
            name="最晚到店"
            :content="detailData.latestArrivalTime"
            class="detail-label"
          />
          <detailLabel
            icon-name="jiudiandizhi-wodehangcheng"
            fill-color="#A9B5C6"
            name="酒店地址"
            :content="detailData.address"
            class="detail-label"
          />
        </div>

        <div v-if="!!changeList && changeList.length > 0">
          <borderLine></borderLine>
          <div class="bottom3-contain">
            <div
              class="biangeng"
              onClick="event.cancelBubble = true"
              @click="biangengOpen = !biangengOpen"
            >
              <span
                class="text"
                :style="biangengOpen ? 'color:#3e90fe;':''"
              >变更详情</span>
              <en-icon
                class="jiantou"
                size="9px"
                name="zhankai"
                :style="biangengOpen ? 'transform: rotate(180deg);':''"
              ></en-icon>
            </div>
            <div
              v-if="biangengOpen"
              class="biangeng-item-contain"
            >
              <div
                v-for="item in changeList"
                :key="item.id"
                class="biangeng-item"
              >
                <span class="type">{{ changeStatusDetail(item.changeStatus) }}</span>
                <span class="time">{{ item.createTime }}</span>
                <div class="reason-block">
                  <span class="reason" v-if="item.changeReason">{{ "取消事由：" + item.changeReason }}</span>
                  <span class="result">{{ "处理结果：" + item.changeResult }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="operationShow"
      class="operation-contain"
    >
      <div v-if="detailData.shareUserList" class="tips-contain">
        <en-icon
          name="bitian"
          size="16px"
          :style="{fill:'#F7BF27'}"
        ></en-icon>
        <div class="tips">
          {{ shareUserName() }}
        </div>
      </div>
      <en-button v-if="reBookButton" class="button" @click="reBookButtonClick">
        重新预定
      </en-button>
      <!--
      <en-button v-if="reBookButton" class="button" @click="deleteButtonClick">
        删除
      </en-button>
      -->
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import tripTotalAmount from "../../components/trip-total-amount";
import borderLine from "../components/border-line";
import detailTop from "../components/detail-top";
import detailLabel from "../components/detail-label";
import detailNode from "../components/detail-node";
import nodeDetailData from "../nodeDetailData";

export default {
  name: "HotelNodeDetail",
  components: {
    borderLine,
    tripTotalAmount,
    detailLabel,
    detailNode,
    detailTop
  },
  props: {
    contentHeight: {
      type: Number,
      default: 800
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [nodeDetailData],
  data() {
    return {
      contentHeightStyle: "",
      bottomContainerStyle: "min-height: 237px;",
      tuigaiOpen: false,
      biangengOpen: true,
      detailEntity: {},
      detailData: {
        leftTop: "入住时间",
        leftBottom: "",
        rightTop: "离店时间",
        rightBottom: "",
        center: "1晚"
      },
      tripNodeId: "",
      changeList: [],
      content: "预订成功后，不允许取消。"
    };
  },
  watch: {
    contentHeight(val) {
      this.contentHeightStyle = `max-height:${val}px;`;
    },
    detail() {
      this.updateDetailData();
    }
  },
  computed: {
    reBookButton() {
      if ((this.detailData.tripStatus === "003"
        || this.detailData.tripStatus === "004"
        || this.detailData.tripStatus === "005")
        && this.detailData.status === "001") {
        return true;
      }
      return false;
    },
    operationShow() {
      if (this.reBookButton || this.detailData.shareUserList) {
        return true;
      }
      return false;
    },
    orderNumberDetail() {
      if (this.detailData.orderId) {
        return `订单编号：${this.detailData.orderId}`;
      }
      return "订单编号：";
    }
  },
  mounted() {
    this.contentHeightStyle = `max-height:${this.contentHeight}px;`;
  },
  methods: {
    // 入住人
    passengerName() {
      if (this.detailData.userList) {
        let names = "";
        for (let i = 0; i < this.detailData.userList.length; i++) {
          const user = this.detailData.userList[i];
          if (names.length === 0) {
            names = user.name;
          } else {
            names = `${names},${user.name}`;
          }
        }
        return names;
      }
      return "";
    },
    // 订单日期
    orderDate() {
      if (this.detailData.createTime) {
        return dayjs(this.detailData.createTime).format("YYYY-MM-DD");
      }
      return "";
    },
    // 共享人
    shareUserName() {
      if (this.detailData.shareUserList) {
        const user = this.detailData.shareUserList[0];
        return `由${user.name}共享`;
      }
      return "";
    },
    // 变更状态
    changeStatusDetail(status) {
      if (status === "000") {
        return "改签成功";
      }
      if (status === "001") {
        return "改签成功";
      }
      if (status === "002") {
        return "改签失败";
      }
      if (status === "003") {
        return "退票成功";
      }
      if (status === "004") {
        return "退票失败";
      }
      if (status === "005") {
        return "预订失败";
      }
      if (status === "006") {
        return "退房成功";
      }
      if (status === "007") {
        return "延期事由";
      }
      if (status === "008") {
        return "退房失败";
      }
      if (status === "009") {
        return "即时叫车";
      }
      if (status === "010") {
        return "预约失败";
      }
      if (status === "011") {
        return "取消用车";
      }
      if (status === "012") {
        return "重新预订";
      }
      if (status === "013") {
        return "移动成功";
      }
      return "";
    },
    updateDetailData() {
      this.detailData = { ...this.detail };
      this.tripNodeId = this.detailData.id;

      const obj = {};
      obj.leftTop = "入住日期";
      obj.rightTop = "离店日期";
      obj.leftBottom = dayjs(this.detailData.arrivalDate).format("MM月DD日");
      obj.rightBottom = dayjs(this.detailData.departureDate).format("MM月DD日");
      const days = dayjs(this.detailData.departureDate).diff(dayjs(this.detailData.arrivalDate), "day");
      obj.center = `${days}晚`;
      this.detailEntity = obj;
      if (this.detailData.changeInfos) {
        this.changeList = [...this.detailData.changeInfos];
      }
    },
    editClick() {
      if (this.operationShow) {
        this.bottomContainerStyle = "min-height: 237px;";
      } else {
        this.bottomContainerStyle = "min-height: 300px;";
      }
    },
    closeClick() {
      this.$emit("closeClick");
    },
    // 重新预定
    reBookButtonClick() {
      const canReBook = this.requestTryNewTripNode({
        tripNodeId: this.tripNodeId,
        tripNodeType: "001"
      });
      if (canReBook) {
        this.closeClick();
        this.$router.push({
          path: "/business-travel/hotel/entry",
          query: this.detailData
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hotel-content-container {
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  .hotel-top-contain {
    position: relative;
    height: 52px;
    .title {
      position: absolute;
      left: 20px;
      top: 19px;
      height: 14px;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      text-align: left;
      color: #333;
    }
    .close-icon {
      position: absolute;
      right: 20px;
      top: 19px;
      width: 14px;
      height: 14px;
    }
    .top-content {
      position: absolute;
      right: 54px;
      top: 0;
      height: 52px;
      display: flex;
      align-items: right;
    }
  }
  .body-container {
    background: #f5f7fa;
    width: 100%;
    max-height: calc(100% - 52px);
    border-radius: 0 0 6px 6px;
    overflow-y: auto;
    .top-container {
      position: relative;
      margin: 0 20px;
      min-height: 230px;
      width: calc(100% - 40px);
      background: #fff;
      border-radius: 6px;
      border: 1px solid #e8ecf2;

      .top-bottom-contain {
        width: 100%;
        min-height: 44px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .tuigai-top-contain {
          height: 44px;
          position: relative;
          .tuigai {
            position: absolute;
            top: 10px;
            left: 18px;
            height: 13px;
            display: flex;
            align-items: center;
            .text {
              color: #333;
            }
            .jiantou {
              margin-left: 10px;
              fill: #a9b5c6;
            }
          }
          .total-amount {
            position: absolute;
            top: 10px;
            right: 18px;
          }
        }

        .tuigai-contain {
          margin: 0 20px 20px;
          border-radius: 4px;
          border: 1px solid #e8ecf2;
          background-color: #f6fafd;
          padding: 8px 12px;
          line-height: 16px;
          color: #636c78;
          height: auto;
        }
      }
    }
    .bottom-container {
      margin: 8px 20px 20px;
      width: calc(100% - 40px);
      min-height: 200px;
      border-radius: 6px;
      border: 1px solid #e8ecf2;
      background: #fff;
      .bottom1-contain {
        position: relative;
        height: 44px;
        width: 100%;
        .title {
          margin: 20px 0 0 20px;
          height: 12px;
          display: inline-block;
          color: #333;
        }
        .time {
          float: right;
          margin: 20px 20px 0 20px;
          height: 12px;
          display: block;
          color: #91a1b7;
        }
      }
      .bottom2-contain {
        width: 100%;
        border-radius: 0 0 6px 6px;
        padding-bottom: 10px;
        .detail-label {
          margin: 15px 20px 0;
        }
      }
      .bottom3-contain {
        position: relative;
        height: auto;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        margin-bottom: 10px;
        .biangeng {
          margin-left: 18px;
          padding-top: 8px;
          height: 38px;
          display: flex;
          align-items: center;
          .text {
            color: #333;
          }
          .jiantou {
            margin-left: 10px;
            fill: #a9b5c6;
          }
        }
        .biangeng-item-contain {
          margin: 6px 20px 18px;
          .biangeng-item {
            position: relative;
            min-height: 80px;
            margin-bottom: 8px;
            padding: 11px 12px;
            border-radius: 4px;
            border: 1px solid #e8ecf2;
            background-color: #f6fafd;
            .type {
              position: absolute;
              left: 12px;
              height: 13px;
              color: #333;
            }
            .time {
              position: absolute;
              right: 12px;
              height: 13px;
              color: #91a1b7;
            }
            .reason-block{
              margin-top: 20px;
              left: 12px;
              span{
                position:inherit;
                display: block;
                min-height: 20px;
                color: #636c78;
              }
            }
          }
        }
      }
    }
  }
  .operation-contain {
    position: relative;
    height: 64px;
    padding: 0 20px;
    border-top: 1px solid #e8ecf2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .button {
      min-width: 72px;
      height: 32px;
    }
    & /deep/ .el-button--small {
      padding-left: 12px;
    }
    .tips-contain {
      position: absolute;
      min-width: 100px;
      height: 60px;
      top: 0;
      left: 20px;
      display: flex;
      align-items: center;
      .tips {
        padding-left: 6px;
        color: #636c78;
      }
    }
  }
}
</style>
