<template>
  <div class="dialog-contain">
    <el-dialog title="" :show-close="false" :visible.sync="dialogOccupyVisible" :append-to-body="true">
      <div class="body-contain" :style="contentWHStyle">
        <div v-if="allNodeList.length > 1" class="previous" @click="previousClick">
          <en-icon
            name="shangxiaqiehuan-wodehangcheng"
            :style="{ fill: '#A9B5C680' }"
            size="48px"
          ></en-icon>
        </div>
        <div v-en-loading="loading" class="content-contain">
          <flight
            v-if="currentItem.tripNodeType === '000'"
            :content-height="dialogHeight"
            :detail="currentDetail"
            @closeClick="closeClick"
          ></flight>
          <hotel
            v-if="currentItem.tripNodeType === '001'"
            :content-height="dialogHeight"
            :detail="currentDetail"
            @closeClick="closeClick"
          ></hotel>
          <car
            v-if="currentItem.tripNodeType === '003'"
            :content-height="dialogHeight"
            :detail="currentDetail"
            @closeClick="closeClick"
          ></car>
          <supplement
            v-if="currentItem.tripNodeType === '002'"
            :content-height="dialogHeight"
            :detail="currentDetail"
            @closeClick="closeClick"
          ></supplement>
        </div>
        <div v-if="allNodeList.length > 1" class="next" @click="nextClick">
          <en-icon
            name="shangxiaqiehuan-wodehangcheng"
            :style="{ fill: '#A9B5C680' }"
            style="transform: rotate(180deg);"
            size="48px"
          ></en-icon>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import flight from "./views/flight";
import hotel from "./views/hotel";
import car from "./views/car";
import supplement from "./views/supplement";
import nodeDetailData from "./nodeDetailData";

export default {
  name: "EnTripOccupyDialog",
  components: {
    supplement,
    flight,
    hotel,
    car
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    allNodeList: {
      type: Array
    },
    currentNode: {
      type: Object
    }
  },
  data() {
    return {
      dialogOccupyVisible: true,
      screenWidth: "",
      screenHeight: "",
      contentWHStyle: "",
      dialogHeight: 800,
      loading: true
    };
  },
  mixins: [nodeDetailData],
  watch: {
    dialogOccupyVisible(val) {
      this.$emit("update:dialogVisible", val);
    },
    screenWidth() {
      this.changeWHFuction();
    },
    screenHeight() {
      this.changeWHFuction();
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
    const self = this;
    window.onresize = () => (() => {
      self.screenWidth = document.body.clientWidth;
      self.screenHeight = document.body.clientHeight;
    })();
    this.loadCurrentData();
  },
  methods: {
    closeClick() {
      this.dialogOccupyVisible = false;
    },
    changeWHFuction() {
      if (this.screenHeight < 900) {
        const height = this.screenHeight < 700 ? 600 : this.screenHeight - 100;
        this.contentWHStyle = `height:${height}px;`;
        this.dialogHeight = height;
      }
      if (this.screenWidth < 1596) {
        const width = this.screenWidth < 1160 ? 800 : this.screenWidth - 360;
        this.contentWHStyle = `${this.contentWHStyle}width:${width}px;`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 1236px;
  height: 800px;
  min-width: 800px;
  min-height: 600px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .previous {
    text-align: right;
  }
  .next {
    text-align: left;
  }
  .content-contain {
    position: relative;
    margin: 0 20px;
    width: 100%;
    /*height: 100%;*/
    border-radius: 4px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
/deep/ .el-dialog__header {
  padding: 0;
  margin: 0;
  height: 0;
  font-size:0;
}
/deep/ .el-dialog__body {
  padding: 0;
  margin: 0;
}
// .dialog-contain {
//   & /deep/ .el-dialog__header {
//     padding: 0;
//     margin: 0;
//     height: 0;
//     font-size:0;
//   }
//   & /deep/ .el-dialog__body {
//     padding: 0;
//     margin: 0;
//   }
// }
</style>
