<template>
  <div class="detail-top-contain">
    <en-icon
      v-if="icon.length > 0"
      :name="icon"
      size="20px"
      :style="{fill:'#F7cF00'}"
      class="flight-icon"
    ></en-icon>
    <span
      class="name"
      :style="icon.length > 0 ? '':'margin-left:17px;'"
    >
      {{ name }}
    </span>
    <en-trip-node-status
      :status="Number(status)"
      :is-dark="true"
      class="status"
    ></en-trip-node-status>
  </div>
</template>

<script>
import enTripNodeStatus from "../../../../components/en-trip-node-status";

export default {
  name: "DeatiTop",
  components: {
    enTripNodeStatus
  },
  props: {
    name: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.detail-top-contain {
  position: relative;
  display: flex;
  justify-items: center;
  padding-top: 4px;
  height: 44px;
  width: 100%;
  .flight-icon {
    margin: 9px 9px 0 17px;
  }
  .name {
    font-size: 14px;
    line-height: 40px;
  }
  .status {
    position: absolute;
    top: 14px;
    right: 20px;
  }
}
</style>
